.info-section{
    margin-left: 26%;
    height:100%;
    width:68%;
    border-radius: 2px;
    background: #FEFEFF;
    box-shadow: 0px 2px 30px 0px rgba(18, 18, 18, 0.08), 3px 0px 30px 0px rgba(255, 255, 255, 0.15);
    overflow-x: hidden;

    .info-header{ 
        width:calc(100% - 6.8%);
        height:3.5rem;
        display:flex;
        align-items: center;
        background-color: #F4EBFF;
        padding-left: 6.8%;

        .heading{
            color: #171717;
            font-size: 1.3rem;
            font-weight: 395;
        }
    }

    .info-container{
        display:grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap:2rem;
        column-gap:4rem;
        padding-left: 6.8%;
        padding-right:1%;
        padding-top: 2rem;
        padding-bottom: 2rem;

        .text1{
            color: #202020;
            font-size: 1.1875rem;
            font-weight: 600;
            padding-bottom: 0.5rem;
        }
        .text2{
            color: #777;
            font-size: 1.0625rem;
            font-weight: 300;
            text-transform: capitalize;
        }
    }
}

// Delivery Details CSS - Death Container
.death-info-container{
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap:2rem;
    column-gap:4rem;
    padding-left: 6.8%;
    padding-bottom: 2rem;

    .text1{
        color: #202020;
        font-size: 1.1875rem;
        font-weight: 600;
    }
    .text2{
        color: #777;
        font-size: 1.0625rem;
        font-weight: 300;
        text-transform: capitalize;
    }
}


//Device Data CSS
.device-info{
    display:grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.25rem;
    margin-bottom: 1.25rem;
    padding-left: 26%;
    .active{
        display:flex;
        flex-direction: column;
        align-items: center;
        gap:6%;
        width: 15.625rem;
        height: 17.5rem;
        border-radius: 8px;
        border: 1.5px solid var(--primary-purple, #A163F1);
        background: #FCFCFC;
        box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.07), 4px 0px 40px 0px rgba(255, 255, 255, 0.25);
        cursor: pointer;
    }

    .inactive{
        display:flex;
        flex-direction: column;
        align-items: center;
        gap:6%;
        width: 15.625rem;
        height: 17.5rem;
        border-radius: 8px;
        background: #FCFCFC;
        box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.07), 4px 0px 40px 0px rgba(255, 255, 255, 0.25);
        cursor: pointer;
    }

    .device-image{
        display:flex;
        align-items: center;
        justify-content: center;
        width: 12.5rem;
        height: 9.375rem;
        padding-top: 10%;
    }

    .hr-line{
        width: 12.5rem;
        height: 0px;
        stroke-width: 1px;
        stroke: #DDD;
        opacity: 0.3;
    }
}


// For Toggling between multiple tabs (intrapartum and postpartum)
.toggle-header{
    height: 19.4%;
    background: #FFF;
    box-shadow: 0px 2px 30px 2px rgba(18, 18, 18, 0.08), 3px 0px 30px 0px rgba(255, 255, 255, 0.25);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
   .download-partograph{
     margin-right: 1rem;

    .download-partograph-button{
       padding: 0.7rem 1.2rem;
        font-size: 1rem;
        font-weight: 300;
        background: #FFF;
        border-radius: 500px;
        stroke-width: 3px;
        border: 0.8px solid #A163F1;
        color:#171717;
        font-family: Poppins;
        text-align: center;
       
    }
    .download-partograph-button:hover{
        color:#FFF;
        background: #A163F1;
       
    }

   }

   .toggle-button{
    display:flex;
    flex-direction: row;
    align-items: center;
    width:max-content;
    justify-content: flex-start;
    margin-left:3rem;
    background: #FFF;
    padding-bottom: 0.8rem;
    padding-top:0.6rem;

   }

   .vertical-line{
    width: 0px;
    height: 1.75rem;
    stroke-width: 1px;
    color: #E4E4E4;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 1rem;
   }

   .active-toggle-text{
        color:  #A163F1;
        font-size: 1.125rem;
        font-weight: 300;
        border-bottom: 3px solid #A163F1;
        border-radius: 3px;
        cursor:pointer;
   }

   .inactive-toggle-text{
        color:  black;
        font-size: 1.125rem;
        font-weight: 300;
        cursor:pointer;
   }

}

//Consent Form CSS
.form-container{
    margin-left: 26%;
    width: 68%;
    height: 100%;
    border-radius: 8px;
    background: #FEFEFF;
    box-shadow: 0px 2px 30px 0px rgba(18, 18, 18, 0.06), 3px 0px 30px 0px rgba(255, 255, 255, 0.25);
    margin-bottom: 2rem;
    padding-left:4.5rem;
    
    .form-header{
        display: flex;
        align-items: center;
        gap:16%;
        padding-top: 3.5%;

        .form-time{
            color: #696969;
            font-family: Poppins;
            font-size: 0.9375rem;
            font-weight: 400;
        }

        .form-heading{
            color: #171717;
            font-size: 1.5rem;
            font-weight: 300;
        }
        .print{
            display:flex;
            justify-content: center;
            align-items: center;
            gap:0.3rem;
            width: 13%;
            height:2.3rem;
            border-radius: 500px;
            border: 1px solid var(--primary-purple, #A163F1);
            background: #FFF;
            margin:auto;
            p{
                color: var(--primary-purple, #A163F1);
                font-size: 0.875rem;
                font-weight: 400;
            }
        }

    }
    .form-content{
        padding-top: 2.5rem;
        padding-right:2.5rem;

        .form-subcontent{
            padding-left: 1rem;
        }
        
        p{
            font-size: 1rem;
            letter-spacing: 1px;
        }
        .underline{
            text-decoration: underline;
            letter-spacing: 1px;
            font-weight: 600;
            padding-left:0.1875rem ;
            padding-right:0.1875rem ;
            text-transform: capitalize;
        }
    }
    .signature-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap:3rem ;
        column-gap:12rem ;
        padding: 5rem;
        padding-top:3rem;
        padding-bottom: 4rem;

        .sign-detail{
            display:flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap:0.5rem;
            p{
                text-transform: capitalize;
            }
            img{
                width:7.5rem;
                height:5rem;
            }
        }
    }
}

// Media Print style to print consent forms and partograph (intrapartum) on A4 Sheet
@page {
    size: A4;
    margin-top: 10mm;
    margin-bottom: 6mm;
    margin-left: 7mm;
    margin-right: 10mm;
  }

@media print {
  html,body * {
    visibility: hidden;
    height: initial !important;
    overflow: initial !important;
  }
  #printableContentHeading, #printableContent * {
    visibility: visible;
  }

  #partograph-container, #partograph-container * {
    visibility: visible;
  }
       
  form{
    display:flex;
    align-items: center;
    justify-content: center;
    padding:2rem;
    margin-right: 2rem;
  }

  .form-heading{
   text-align: center;
  }

  .form-container {
    width: 200mm; 
    height: 297mm; 
    margin-right: 1.3rem;
    margin-left:1.3rem;
    margin-top: 2rem;
    box-sizing: border-box;
    page-break-after: always;
  }

  .signature-container{
 
    .sign-detail{
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            width: 5rem; 
            height: 3.25rem; 
          }
       
    }

  }
}

// Alert Section CSS
.alert-section{
    padding-bottom: 20rem;
    height:max-content;

    hr{
        width:93%;
        height:1px;
        border-collapse: collapse;
        opacity:0.5;
    }

    .header-background{
        background-color:#F4EBFF;
        padding-bottom: 1rem;
    }
    .alert-heading{
        display:grid;
        grid-template-columns: repeat(3, 1fr);
        padding-left: 4.8%;
        padding-top: 1rem;
      
        .header-text{
            color: #171717;
            font-size: 1.25rem;
            font-weight: 500;
        }
        .data-text{
            text-transform: capitalize;
            color: #606060;
            font-size: 1.0625rem;
            font-weight: 400;
        }
    }
}

//Referral Section CSS
.referral-section{
    padding-bottom: 10rem;
    height:max-content;
    width:100%;
    display: flex;
    flex-direction: column;
    gap:1rem;
    

    hr{
        width:93%;
        border-collapse: collapse;
        opacity:0.5;
    }

    .referral-heading{
        height:3rem;
        width:calc(100% - 4.6%);
        background: #F0E4FF;
        padding-left: 3.5rem;
        padding-top: 1rem;
        
        .header-text{
            color: #171717;
            font-size: 1.25rem;
            font-weight: 500;
        }
        
    }
    .referral-column{
        display:flex;
        flex-direction: row;
        gap:12%;
        padding-left: 4.8%;
        padding-top:2rem;
        padding-bottom: 2rem;
        .text-one{
            color: #171717;
            font-size: 1.1875rem;
            font-weight: 600;
            padding-bottom: 0.4rem;
        }
        .text-two{
            color: #606060;
            font-size: 1.0625rem;
            font-weight: 400;
            text-transform: capitalize;
        }
    }
}

.reload-div{
    padding-left:24%;
    padding-top: 5%;;
}

.loading-div{
    padding-left: 8%;
    margin-top: 10rem;
}

.no-data-div{
    padding-left:26%;
    padding-top:3.125rem;
}

//Post Partum Data
.postpartum-table-header{
    display:flex;
    flex-direction: row;
    gap: 3rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 3rem;

}

//Intrapartum CSS
.table-layout {
    display: grid;
    grid-template-columns: repeat(4, minmax(3.5rem, 43.75rem));
    justify-content: space-between;
    border: 0.5px solid #EAEAEA;

    .header-text{
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.875rem;
        text-align: left;
        color:#171717;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        padding-left:2.5rem;
    }

    .table-text{
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.6875rem;
        text-align: left;
        color: #606060;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 2.5rem;
    }
}

.header{
    border: 0.5px solid #171717;
}

.active-table{
    border: 1px solid black;
    padding-left: 1rem;
    margin-left: 3rem;
}

.table{
   // margin-left: 2rem;
    padding-left: 3rem;
   
}

.postpartum-table{
    display: grid;
    grid-template-columns: repeat(2, minmax(3.5rem, 43.75rem));
    justify-content: space-between;

}

.complication-container{
    display: grid;
    grid-template-columns: auto auto auto; 
    gap: 1rem; 
    margin: auto;
    padding: 2rem 6.8%;

    .complications{
        display: contents;
        
        .complication-title {
            color: #777;
            font-size: 1.0625rem;
            font-weight: 300;
            text-transform: capitalize;
            padding: 0.5rem 0;
        }
          
        .complication-date {
            color: #777;
            font-size: 1.0625rem;
            font-weight: 300;
            text-transform: capitalize;
        }
        
        .complication-time {
            color: #777;
            font-size: 1.0625rem;
            font-weight: 300;
            text-transform: capitalize;
        }

        .text1{
            color: #202020;
            font-size: 1.1875rem;
            font-weight: 600;
            padding-bottom: 0.5rem;
        }
        .text2{
            color: #777;
            font-size: 1.0625rem;
            font-weight: 300;
            text-transform: capitalize;
        }
    }
}

.doctor-notes-container{
    display: flex;
    flex-direction: column;
    padding: 2rem 6.8%;

    #doctor-select {
        appearance: none; /* Remove default arrow icon */
        -moz-appearance: none;
        -webkit-appearance: none;
        background: url('../../../public/select-icon.svg') no-repeat ;
        background-position: calc(100% - 3.125rem) center; /* 50px from the right */
        padding-right: 3.75rem;         
    }
      
    #doctor-select:hover {
        border-color: #aaa;
    }
      
    /* Style the dropdown options */
    #doctor-select option {
        padding: 1.5625rem;
        background: #F5F5F5;
        height: 3.125rem;
        color: #171717;
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .select-doctor-label{
        color: #858585;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.54px;
        margin-bottom: 1rem;
    }

    .select-doctor{
        border-radius: 8px;
        border: 1px solid #939393;
        background: #FEFEFF;
        width: 100%;
    }

    .option-doctor{
        background: #F5F5F5;
        height: 3.125rem;
        color: #171717;
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }


    .note-displayer{
        display: flex;
        justify-content: space-between;
        margin: 0px 1.25rem;
        border-bottom: solid 1px #D9E4E4;

        .note-date{
            padding: 1.25rem 0px 0.625rem 0px;
            color: #747474;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            
        }
    }
}



.intrapartum-info-container{
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 1rem;
    width:90%;
    margin:0px auto;

    .breakableText {
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
    }

   .intrapartum-info-section{
       width:100%;
       border:1px solid black;
   }

    .text1{
        color: #202020;
        font-size: 0.875rem;
        font-weight: 600;
        text-transform: capitalize;
        padding-left: 1rem;
        padding-right: 0.5rem;
        padding-top: 1rem;
    }
    .text2{
        color: #777;
        font-size: 0.875rem;
        font-weight: 400;
        text-transform: capitalize;
        word-wrap: break-word;
        padding-left: 1rem;
        padding-bottom: 0.6rem;
   }
}

.printable-only-div{
    display:none;
}




/* Screen height between 600px and 700px */
@media (min-height: 37.5rem) and (max-height: 43.75rem) { 
    html {
        font-size: 14px;
    }
}

/* Screen height between 700px and 800px */
@media (min-height: 43.75rem) and (max-height: 50rem) { 
    html {
        font-size: 15px;
    }
}

/* Screen height between 800px and 900px */
@media (min-height: 50rem) and (max-height: 56.25rem) { 
    html {
        font-size: 16px;
    }
}

/* Screen height between 900px and 1000px */
@media (min-height: 56.25rem) and (max-height: 62.5rem) { 
    html {
        font-size: 17px;
    }
}

/* Screen height between 1000px and 1100px */
@media (min-height: 62.5rem) and (max-height: 68.75rem) { 
    html {
        font-size: 18px;
    }
}

/* Screen height above 1100px */
@media (min-height: 68.75rem) { 
    html {
        font-size: 19px;
    }
    
}

