
.chart-light{
    height: 100vh;
    width: 100vw;  
    overflow-x: hidden;
    color: #FFF;
}

.chart-dark{
    height: 100vh;
    width: 100vw;  
    overflow-x: hidden;
    color: #FFF;
}

.delayedByOneMin-div{
    border-radius: 4px;
    background: #FFF;
    display: flex;
    padding: 2px 0px 2px 20px;
    font-family: 'Poppins', sans-serif;
    margin-left: 45px;
    margin-top: 2px;
    margin-right: 30px;
}

.delayedByOneMin-text{
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 10px;
}

.small-warning{
    width: 27px;
    height: 27px;
}

.back-light{
    color: black;
    font-size: 1.4rem;
    font-weight: 400;

}

.back-dark{
    color: #FEFCFF;
    font-size: 1.4rem;
    font-weight: 400;

}

.navbar{
    font-family: 'Poppins', sans-serif;
    padding: 15px 0 10px 10px;
    width: 100vw;
    background: #2C2C2C;
    background-blend-mode: lighten;
    .nav{
        display: flex;
        justify-content: space-between;
        margin-right: 30px;

        .live-view{
            color: #FEFCFF;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: center;
           
        }
        .delayed-timer{
            color: #FEFCFF;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: center; 
            margin-right: 40px;
        }
    }

}

.navbar-white{
    background:rgba(219, 219, 219, 1);
    .nav{

        .live-view{
            color: black;
        }
        .delayed-timer{
            color: black;
            
        }
    }

}

.navbar-dark{
    background: #2C2C2C;
    .nav{

        .live-view{
            color: #FEFCFF;
        }
        .delayed-timer{
            color: #FEFCFF;
        }
    }

}

.detail{
    display: flex;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
    color: #FEFCFF;
    margin-left: 45px;
    margin-bottom: 10px;
    margin-right: 10px;
    padding-right: 20px;
    font-size: 20px;
    border-radius: 4px;
    border: 0.7px solid #7A7A7A;
    background: #2C2C2C;

    .name{
        margin-left: 10px;
        padding: 10px;  
        text-align: center;
    }

    .age{
        margin-left: 10px;
        padding: 10px;  
        text-align: center;
    }

    .ga{
        margin-left: 10px;
        padding: 10px;  
        text-align: center;
    }

    .device-id{
        margin-left: 10px;
        padding: 10px;  
        text-align: center;
    }

    .monitoring-time{
        margin-left: 10px;
        padding: 10px;  
        text-align: center;
    }
}

.light-theme {
    background-color: white;
    color: black;
    border:1px solid rgba(219, 219, 219, 1)
}
  
.dark-theme{
    background-color: black;
    color: white;
}

.patient-detail{
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: 80vh;
    font-family: 'Poppins', sans-serif;
    margin-top: 20px;
   

    .graph-layout{
        width: 82%;
    }
    
    .detail-layout{
        height: 85vh;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        gap:1rem;
        margin-right: 30px;

        .fhr-fhr2-mhr-card{
            display: flex;
            flex-direction: column;
            align-items: center;
            
            .hr{
                color: #A7A7A7;  
                width: 98%;
            }

            .monitoring-data{
                font-size: 24px;
                font-weight: 500;
                letter-spacing: 0.48px;
                text-align: center;
                margin-top: 1rem;
            }

            .monitoring-data-layout{
                width: 15vw;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                .fhr-layout-card{
                    display: flex;
                    justify-content: space-around;
                    background: #2C2C2C;
                    margin: 20px 10px;
                    
                    .name-layout{
                        margin-top: 10px;
                        text-align: center;
                        .fhr{
                            font-size: 35px;
                            font-weight: 300;
                            margin-bottom: 10px;
                        }
    
                        .imageLogo{
                            width: 25px;
                            height: 25px;
                        }
                    }
    
                    .number-layout{
                        display: flex;
                        margin-top: 10px;
                        
                        .number{
                            color: #05C901;
                            font-size: 68px;
                            font-weight: 500;
                            text-align: center;
                        }
                        .critical-number{
                            color: #E20000;
                            font-size: 68px;
                            font-weight: 500;
                            text-align: center;
                            margin-top: 20px;
                        }
                        .bpm{
                            font-size: 16px;
                            font-weight: 300;
                            text-align: center;
                            margin-top: 50px;
                            margin-left: 5px;
                        }
                    }
                } 
            }
        }

        .uc-card{
            margin-top: 18px;
            min-height: 200px;
            border-radius: 4px;
            padding: 5px 5px;

            .uc-logo-layout{
                display: flex;
                margin-left: 30px;
                .imageLogo{
                    width: 25px;
                    height: 25px;
                    margin-top: 12px;
                    margin-left: 10px;
                    
                }
                .uc-text{
                    font-size: 35px;
                    font-weight: 300;
                    margin: 0;
                    padding: 0;
                }
            }

            .uc-number-layout{
                display: flex;
                justify-content: space-between;
                margin: 0px 20px;
                .uc-number{
                    text-align: center;
                    .number{
                        font-size: 55px;
                    }
                    .small-text{
                        font-size: 12px;
                        font-weight: 300;
                    }
                    .large-text{
                        font-size: 15px;
                    }
                }
            }
        }

        .toco-card{
            margin-top: 18px;
            display: flex;
            justify-content: space-around;
            height: 100px;
            border-radius: 4px;
            padding: 5px 5px 15px 5px;
            

            .toco-name-layout{
                margin-top: 5px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                
                .fhr{
                    font-size: 30px;
                    font-weight: 300;
                    margin-bottom: 10px;
                }

                .imageLogo{
                    width: 25px;
                    height: 25px;
                }
            }

            .toco-number-layout{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                
                .toco-number{
                    color: #05C901;
                    font-size: 55px;
                    font-weight: 500;
                    text-align: center;
                    height: 70px;
                }

                .toco-number-light{
                    color: black;
                    font-size: 55px;
                    font-weight: 500;
                    text-align: center;
                    height: 70px;
                }
                
                .pressure{
                    font-size: 16px;
                    font-weight: 300;
                    text-align: center;
                    margin-bottom: 5px;
                }
            }
        }
        
    }

    .detail-layout-light{
      
        .fhr-fhr2-mhr-card{
            background: rgba(248, 248, 248, 1);
            
            .hr{
                color: #A7A7A7;  
                width: 98%;
            }

            .monitoring-data{
                color: black;
            }

            .monitoring-data-layout{
            
                .fhr-layout-card{
                    background:  rgba(248, 248, 248, 1);
                    
                    .name-layout{

                        .fhr{
                            color: rgba(0, 0, 0, 1);
                        }
                    }
    
                    .number-layout{
                        .bpm{
                            color: rgba(0, 0, 0, 1);
                        }
                    }
                } 
            }
        }

        .uc-card{
            background: rgba(248, 248, 248, 1);

            .uc-logo-layout{
                
                .uc-text{
                    color: black;
                }
            }

            .uc-number-layout{
                .uc-number{
            
                    .number{
                        color: black;
                    }

                    .small-text{
                        color: black;
                    }

                    .large-text{
                        color: black;
                    }
                }
            }
        }

        .toco-card{
            background: rgba(248, 248, 248, 1);
            
            .toco-name-layout{
                .fhr{
                    color: black;
                }
            }

            .toco-number-layout{
                
                .pressure{
                    color: black;
                }
            }
        }

    }

    .detail-layout-dark{
      
        .fhr-fhr2-mhr-card{
            background: #2C2C2C;

            .hr{
                color: #A7A7A7;  
                width: 98%;
            }

            .monitoring-data{
                color: #FEFCFF;
            }

            .monitoring-data-layout{

                .fhr-layout-card{
                    background: #2C2C2C;
                   
                    .name-layout{
                       
                        .fhr{
                            color: #FEFCFF;
                            
                        }
                    }
    
                    .number-layout{
                        .bpm{
                            color: #FFF;
                        }
                    }
                } 
            }
        }

        .uc-card{
            background: #2C2C2C;

            .uc-logo-layout{
                .uc-text{
                    color: #FEFCFF;
                }
            }

            .uc-number-layout{
                .uc-number{
                    .number{
                        color: #FEFCFF;
                    }
                    .small-text{
                        color: #FFF;
                    }
                    .large-text{
                        color: #FFF;
                    }
                }
            }
        }

        .toco-card{
            background: #2C2C2C;
            
            .toco-name-layout{
                .fhr{
                    color: #FEFCFF;
                }
            }

            .toco-number-layout{
                
                .pressure{
                    color: #FFF;
                }
            }
        }

    }
}

.highcharts-light .highcharts-background {
    fill: white !important;
  }
  
  .highcharts-dark .highcharts-background {
    fill: #1c1c1c !important;
  }


@media (max-width : 1700px){
    .graph-layout{
        width: 72% !important;
        margin-left: 30px;
    }

    .detail-layout{
        width: 20vw;
        height: 100vh;
        margin-right: 50px !important;
    }
    .fhr-fhr2-mhr-card{
        padding-bottom: 10px !important;
    }

    .fhr-layout-card{
        margin: 0px!important;
        justify-content: space-between !important;
    }
    
    .hr{
        margin: 5px 0px 0px 0px !important;
    }

    .uc-card{
        margin-top: 5px !important;
        padding: 0px !important;
    }

    .toco-card{
        margin-top: 6px !important;
        padding: 0px !important;
    }

    .monitoring-data{
        margin-top: 2px !important;
    }

    .number{
        color: #05C901;
        font-size: 55px !important;
        font-weight: 500;
        text-align: center;
    }
    .critical-number{
        color: #E20000;
        font-size: 55px !important;
        font-weight: 500;
        text-align: center;
    }


}

