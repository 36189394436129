.print-partograph{
    display:flex;
    justify-content: center;
    align-items: center;
    margin-top:2rem;
    margin-bottom:1rem;

    .print-partograph-button{
        display: inline-flex;
        align-items: center;
        justify-items: center;
        color: #FFF;
        padding: 0.7rem 1.5rem;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border-radius: 100px;
        font-family: 'Poppins';
        border: 1px solid var(--primary-purple, #A163F1);
        background: #A163F1;
        margin-left: 30px;
    }

    
}

.partograph-info-container{
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 1rem;
    width:90%;
    margin:0px auto;

    .breakableText {
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
    }

   .partograph-info-section{
       width:100%;
       border:1px solid black;
   }

    .text1{
        color: #202020;
        font-size: 14px;
        font-weight: 600;
        text-transform: capitalize;
        padding-left: 1rem;
        padding-right: 0.5rem;
        padding-top: 1rem;
    }
    .text2{
        color: #777;
        font-size: 14px;
        font-weight: 400;
        text-transform: capitalize;
        word-wrap: break-word;
        padding-left: 1rem;
        padding-bottom: 0.6rem;
   }
}

.view-partograph{
    width:95%;
    margin: 0px auto;

}

@page {
    size: A4;
    margin-top: 10mm;
    margin-bottom: 6mm;
    margin-left: 7mm;
    margin-right: 10mm;
}

@media print {
  html,body {
    visibility: hidden;
    height:100vh; 
    margin: 0 !important; 
    padding: 0 !important;
    overflow: hidden;
    -webkit-print-color-adjust: exact;
  }

  body > * {
    visibility: hidden;
  }


  #partograph-container, #partograph-container * {
    visibility: visible;
  }
}
