html{
    background: #FBF8FF;
    width: 100%;
    height: 100%;
}

body{
    background: #FBF8FF;
    width: 100%;
    height: 100%;
}

.report-section{
    flex: 1;
    margin-left: 17%; /* Width of the sidebar */
    background: #FBF8FF;
    overflow-x: hidden ;
    height: 100%;

    .error-div{
        margin-top: 15.625rem;
        margin-left: 4.375rem;
    }

    .reports{
        padding:0.8rem 1rem 0.8rem 1.5rem;
        margin-top: 0.625rem;

        .search-filter-div{
            display: flex;
            flex-direction: column;
            border-radius: 12px;
            margin: 4.5rem 1rem 0px 0px ;
            background: #FFF;
            box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.12), 0px 4px 40px 0px rgba(255, 255, 255, 0.25);

            .search-filter{
                display: flex;
                align-items: center;
                gap:2rem;
                padding:2rem 1.25rem;

                .search-input-div{
                    display:flex;
                    flex-direction: row;
                    align-items: center;
                    gap:0.5rem;
                    width: 52%;
                    border-radius:100px;
                    border: 1px solid #A163F1;
                    background: #FFF;
                    height: 3.4375rem;
                    padding-left: 1.6rem;

                    .search-icon{
                        width:1.25rem;
                        height:1.25rem;
                    }

                    .search-input{
                        background-color: transparent;
                        border: 0px solid;
                        margin-top: 2px;
                        width:90%;
                        font-size: 1.125rem;
                        font-family: poppins;
                    }

                    .search-input::placeholder{
                        color: #666;
                        font-family: Poppins;
                        font-size: 1.125rem;
                        font-weight: 300;
                        outline: none;
                        width:100% 
                    }
                    
                    textarea:focus, input:focus{
                        outline: none;
                    }

                    .search-button{
                        padding: 0.95rem 1.8rem;
                        border-radius: 0px 6.25rem 6.25rem 0px;
                        background: #A163F1;
                        color: #FFF;
                        font-family: Poppins;
                        font-size: 1.125rem;
                        font-weight: 500;
                    }
                }
                
                .filter-button{
                    display: flex;
                    border-radius: 50px;
                    width:12rem;
                    border: 1px solid #A163F1;
                    align-items: center;
                    gap:0.5rem;
                    justify-content: center;
                    padding: 0.8rem 1.8rem;
                    cursor: pointer !important;

                    .filter-icon{
                        width: 1.7rem;
                        height: 1.7rem;
                    }
                    .filter-text{
                        color: #666;
                        font-family: Poppins;
                        font-size: 1.125rem;
                    }
                }
                
                .close-button{
                    display: flex;
                    border-radius: 50px;
                    border: 1px solid #A8A8A8;
                    align-items: center;
                    gap:0.5rem;
                    justify-content: center;
                    padding: 0.9rem 2.5rem;
                    cursor: pointer !important;

                    .close-icon{
                        width: 1.25rem;
                        height: 1.25rem;
                    }
                    .close-text{
                        color: #666;
                        font-family: Poppins;
                        font-size: 1.125rem;
                    }
                }
            }

            .container{
                display: flex;
                flex-direction: column;
                width:100%;
                margin:0 0.625rem;
                padding-top: 0.625rem;
                padding-bottom:0.8rem;

                .date-filter-container{
                    display:flex;
                    flex-direction: row;
                    gap: 3rem;

                    .date-picker{
                        display: flex;
                        flex-direction:column;
                        gap: 0.6875em;
                        width:16.875rem;
                        max-height: max-content;

                        .text1{
                            color: #171717;
                            font-size: 1rem;
                            font-weight: 500;
                        }

                        .css-i4bv87-MuiSvgIcon-root {
                            fill: #B789F3;
                            
                        }
                    }
                }
            }

            .filter-button-div{
                display: flex;
                margin-left: 1rem;

                .active-filter-button{
                    border-radius: 500px;
                    background: #DABDFF;
                    border: 0.5px solid #A163F1;
                    padding: 0.8rem 2.2rem; 
                    border: none;
                    color: #171717;
                    font-size: 1rem;
                    font-family: poppins;
                    font-weight: 300;
                    line-height: normal;
                    text-transform: capitalize;
                    margin: 0.625rem 0.625rem 1.875rem 1.25rem;
                    cursor: pointer;
                }

                .inactive-filter-button{
                    border-radius: 500px;
                    border: 0.5px solid #A163F1;
                    padding: 0.8rem 2.2rem; 
                    background: #F9F9F9;
                    color: #707070;
                    font-size: 1rem;
                    font-family: poppins;
                    font-weight: 300;
                    line-height: normal;
                    text-transform: capitalize;
                    margin: 0.625rem 0.625rem 1.875rem 1.25rem;
                    cursor: pointer;
                }
            }
            
            .filter-container{
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                align-items: center;
                margin-left: 2.2rem;
        
            }

            .apply-filter-container{
                display:flex;
                justify-content: flex-end;
                margin-right:3rem;

                .apply-button{
                    border-radius: 50px;
                    border: 1px solid #A163F1;
                    background-color:#A163F1;
                    align-items: center;
                    width: 12.5rem;
                    height: 3.4375rem;
                    color:white;
                    justify-content: center;
                    font-size: 1.125rem;
                    font-weight: 300;
                    font-family: poppins;
                    text-transform: capitalize;
                    margin-bottom:2.2rem;
                    cursor: pointer !important;
                }

                .inactive-apply-button{
                    border-radius: 50px;
                    border: 1px solid  #9B9B9B;
                    align-items: center;
                    width: 12.5rem;
                    height: 3.4375rem;
                    color:#9B9B9B;
                    justify-content: center;
                    font-size: 1.125rem;
                    font-weight: 300;
                    font-family: poppins;
                    text-transform: capitalize;
                    margin-bottom:2.2rem;
                    cursor: pointer !important;
                }
            }
           
        }
    }
}

.result-container{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;
    padding-left: 1.8%;
    padding-right: 6%;
    p{
        color: #828282;
        font-size: 1rem;
        font-weight: 300;
        line-height: 1.6875rem;
    }
    .download-link{
        text-decoration: underline;
        color:rgba(187, 141, 245, 1);
        cursor:pointer !important
    }
}

.patient-list{
    display: flex;
    flex-direction: column;
    margin-top:1.5rem;
}

.patient-obj{
    height:fit-content;
    width:98%;
    border-radius: 12px;
    background: #FFF; 
    box-shadow: 0px 4px 40px 0px #12121214,4px 0px 40px 0px #FFFFFF24;
    border: 0px, 0px, 1px, 0px;
    margin-bottom:  1.6rem; 
}

.patient-details{
    height:4.375rem;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 0.75rem 0.75rem 0px 0px;
    border-bottom: 1px solid #E3E3E3;
    background:rgba(255, 255, 255, 1);
    padding:0px 4% 0px 2%; 

    .patient-data{
        color: #171717;
        font-size: 1.2rem;
        font-weight: 500;
    }
    .patient-update{
        display:flex;
        flex-direction:row;
        gap:2.5rem;
        justify-items: right;
        align-items: center;

        .update{
            display:flex;
            flex-direction:row;
            align-items: center;
            justify-content: center;
            gap:0.65rem;

            .last-sync-text{
                font-size: 0.9rem;
                font-weight: 200;
                line-height: 1.6rem;
                color:rgba(105, 105, 105, 1);
            
            }
        }
    }
}

.information{
    display:flex;
    flex-direction: column;
    gap:1.75rem;
    font-size: 0.75rem;
    padding-left:2%;
    padding-right: 3.4375rem;
    margin-top: 1.5rem;

    .patient-info{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        row-gap:1.5rem ;
        column-gap:6rem ;
     }
}


 .text-1{
    color: #292929;
    font-size: 1.1rem;
    font-weight: 300;
 }

 .text-2{
    color:#6A6A6A;
    font-size: 1rem;
    font-weight: 300;
    line-height: normal;
    text-transform: capitalize;
 }

 .min-width{
    width: fit-content;
 }

 .buttons{
    display:flex;
    justify-content: flex-end;
    gap:2.1rem;
    margin-bottom: 1.5rem;
    cursor:pointer !important;
 }

 .osi-buttons{
    display:flex;
    justify-content: flex-end;
    margin-bottom: 1.5rem;
    cursor:pointer !important;
}

button{
    cursor:pointer !important;
}

.active-button{
    padding: 0.8rem 5.5rem; 
    color:#171717;;
    font-size: 1.125rem;
    font-weight: 300;
    border-radius: 500px;
    border: 0.8px solid #A163F1;
    background: #EEDFFF;
    font-family: Poppins;
    text-align: center;
}

.active-partograph-button{
    padding: 0.8rem 4rem;  
    font-size: 1.125rem;
    font-weight: 300;
    background: #FFF; 
    border-radius: 500px;
    border: 0.8px solid #A163F1;
    color:#A163F1;
    font-family: Poppins;
    text-align: center; 
}

.inactive-partograph-button{
    padding: 0.8rem 4rem; 
    font-size: 1.125rem;
    font-weight: 300;
    background: #F5F5F5; 
    color:#A7A7A7;
    border-radius: 500px;
    border: 1px solid #D1D1D1;
    font-family: Poppins;
    text-align: center;
}

// .active-button:hover{
//     background-color:#A163F1;
//     color:white;
// }

// .active-partograph-button:hover{
//     background:#A163F1;
//     color:white;
// }

.reference-detail{
    height: 3rem;
    background-color: rgba(195, 195, 195, 1);
    box-shadow:2px 2px 9px 2px rgba(18, 18, 18, 0.12);
    border-radius: 0px 0px 1.25rem 1.25rem;
    
    p{
       font-size: 1.5rem;
       line-height: 1.75rem;
       font-weight: 400;
       padding-top:0.625rem;
       padding-bottom:0.625rem;
       padding-left:3.3125rem;
       color:rgba(23, 23, 23, 1)
    } 
}

.postpartum-box{
    display: flex;
    width: 9rem;
    height: 1.875rem;
    padding: 10px 18px;
    color: #FF983E;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    background: #FFEEDF;  
}

.intrapartum-box{
    display: flex;
    width: 9rem;
    height: 1.875rem;
    padding: 10px 18px;
    color:  #FF64B3;
    font-size: 1rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    background:#FFEFF7;  
}

.completed-box{
    display: flex;
    width: 160px;
    height: 30px;
    padding: 10px 18px;
    color:  #5B5B5B;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    background:#EFEFEF ;  
}

.postpartum-border{
    border: 1px solid #EE9B4F;
    box-shadow: 0px 4px 40px 0px rgba(18, 18, 18, 0.08), 4px 0px 40px 0px rgba(255, 255, 255, 0.14);
}

.intrapartum-border{
    border: 1px solid #EC99C3;
    box-shadow: 0px 4px 40px 0px rgba(18, 18, 18, 0.08), 4px 0px 40px 0px rgba(255, 255, 255, 0.14);
    
}

.completed-border{
    border: 1px solid #B9B9B9;
    box-shadow: 0px 4px 40px 0px rgba(18, 18, 18, 0.08), 4px 0px 40px 0px rgba(255, 255, 255, 0.14);
}

.referral-div{
    display:flex;
    flex-direction: column;

    .referral-info{
        border-radius: 0px 0px 0.75rem 0.75rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        .person-image{
            height:1.8725rem;
            width:1.8725rem;
            padding-left: 2%;
            padding-right: 1rem;
        }
        .arrow-image{
            padding-left:1rem;
            padding-right: 2.5rem;
        }
    }
    .yellow-background{
      background: #FFE9BE;
    }
    .blue-background{
        background: #C2E2FF;
    }
    .brown-background{
        background: #E0CEC4;
    }
    .pink-background{
        background: #FFDAE9;
    }

}

.search-error-div{
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: #FFF;
    padding:0.625rem;
    margin-right: 1.25rem;
}

.show-chatbot{
    z-index:1000;
    position: fixed;
    bottom:11.25rem;
    right:2.5rem;
    background-color: white;
    padding: 0.7rem 1.3rem; 
    color:#A163F1;
}



// /* Media query for screens larger than 768px (small desktop) */
// @media (min-width: 769px) {
//     .report-section {
//       background-color: lightblue;
//       padding: 15px;
//     }
//   }
  
//   /* Media query for screens larger than 1200px (large desktop) */
// @media (min-width: 1201px) {
//     .report-section {
//       background-color: lightgreen;
//       padding: 30px;
//     }
//   }


.selected-filters{
    margin: 1.25rem 1.875rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem; /* Adjust the space between chips */
    max-width: 100%; /* Ensure the container does not exceed the width of its parent */
    overflow: hidden; /* Hide the overflow */
}

.helper-text {
    color: red;
    font-size: 0.75rem;
    margin-left: 20rem;
 }


//  .clear-button{
//     border: 0.5px solid black;
//     border-radius: 50%;
//     margin-right: 10px;
//  }

/* Screen height between 600px and 700px */
@media (min-height: 37.5rem) and (max-height: 43.75rem) { 
    html {
        font-size: 14px;
    }
   
}

/* Screen height between 700px and 800px */
@media (min-height: 43.75rem) and (max-height: 50rem) { 
    html {
        font-size: 15px;
    }
   
}

/* Screen height between 800px and 900px */
@media (min-height: 50rem) and (max-height: 56.25rem) { 
    html {
        font-size: 16px;
    }
    
}

/* Screen height between 900px and 1000px */
@media (min-height: 56.25rem) and (max-height: 62.5rem) { 
    html {
        font-size: 17px;
    }
   
}

/* Screen height between 1000px and 1100px */
@media (min-height: 62.5rem) and (max-height: 68.75rem) { 
    html {
        font-size: 18px;
    }
   
}

/* Screen height above 1100px */
@media (min-height: 68.75rem) { 
    html {
        font-size: 19px;
    }  
}
