.casesheet-container{
    padding-top: 1rem;

    .close-button{
        margin-left:5%;
        border: 1px solid black;
        border-radius: 50%;
    }
    .sheet-header{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
    }
    
}

.print-button{
    display: inline-flex;
    align-items: center;
    justify-items: center;
    color: #FFF;
    padding: 0.7rem 1.5rem;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 100px;
    font-family: 'Poppins';
    border: 1px solid var(--primary-purple, #A163F1);
    background: #A163F1;
    margin-left: 30px;
}


.sheet-info-header{ 
    width:98.8%;
    height:2rem;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color:black;
    margin:10px;
    margin-bottom:0px;
    
    .heading{
        color:white;
        font-size: 16px;
        font-weight: 395;
    }
}

.sheet-info-container{
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    margin:10px;
    margin-right: 14px;
    margin-top:0px;

    .breakableText {
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
    }

   .sheet-info-section{
       width:100%;
       border:1px solid black;
   }

    .text1{
        color: #202020;
        font-size: 14px;
        font-weight: 600;
        text-transform: capitalize;
        padding-left: 1rem;
        padding-right: 0.5rem;
        padding-top: 1rem;
    }
    .text2{
        color: #777;
        font-size: 14px;
        font-weight: 400;
        text-transform: capitalize;
        word-wrap: break-word;
        padding-left: 1rem;
        padding-bottom: 0.6rem;
   }
    
}

// Table Header CSS
.table-header {
    text-align: center;
    margin: 10px;
    margin-top: 0px;
}

.content-label{
    font-size: 16px;
}

.table-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(56px, 700px));
    width: 100%;
    justify-content: space-between;
}

// Consent Form CSS
.sheet-form-container{
    width: 98.7%;
    background: #FFF;
    box-shadow: 0px 2px 30px 0px rgba(18, 18, 18, 0.06), 3px 0px 30px 0px rgba(255, 255, 255, 0.25);
    margin-bottom: 0.5rem;
    padding-bottom: 1rem;
    margin-left: 10px;
    border:1px solid black;

    .form-header{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 0.5rem;
     
        .form-heading{
            color: #171717;
            font-size: 18px;
            font-weight: 500;
        }
    }
    .form-content{
        padding-left:4.5rem;
        padding-bottom: 1.5rem;
        p{
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 1px;
        }
        .underline{
            text-decoration: underline;
            letter-spacing: 1px;
            font-weight: 600;
            padding-left:2px ;
            padding-right:2px ;
            text-transform: capitalize;
        }
    }
    .signature-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap:0.5rem ;
        column-gap:20rem ;
        padding-top:0.5rem;
        .sign-detail{
            display:flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            p{
                text-transform: capitalize;
                font-size: 14px;
            }
            img{
                width:80px;
                height:60px;
            }
        }
    }
}

.doctor-sign{
    float:right;
    padding-top: 5rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
}


// Media Print style to print Case Sheet on A4
@page {
    size: A4;
    margin-top: 6mm;
    margin-bottom: 6mm;
    margin-left: 7mm;
    margin-right: 7mm;
  }

  @media print {
    
    html,body {
      height:100vh; 
      margin: 0 !important; 
      padding: 0 !important;
      overflow: hidden;
    }
    body * {
      -webkit-print-color-adjust: exact;
    }
    .print-container {
        page-break-inside: avoid;
        display: block;
      }

    .margin-section {
      margin-top: 1.5rem!important;
    }

    .sheet-info-container{
        page-break-inside: avoid;
    } 

    .sheet-info-header{
        width:97.5%;
        margin-bottom: 0;
        margin-top: 10px;
    }
    .breakableText {
        max-width:max-content;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
    }

    .sheet-header {
      font-weight: 600;
    }
    
    .content-label{
      font-size: 16px;
      page-break-inside: avoid;
    }

    .text2{
        page-break-inside: avoid;
        padding-right: 0.5rem;
    }

    .text1{
        page-break-inside: avoid;
    }

    .content-value {
      page-break-inside: avoid;
    }
    .print_postparto_section {
      width: 50%!important;
    }
    .page {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
    }

    .sheet-form-container {
        width: 95.7%;
        background: #FFF;
        margin-bottom: 0.5rem;
        padding-bottom: 1rem;
        margin: 10px;
        margin-right: 20px;
        padding-right: 10px;
        border:1px solid black;
    
        .form-header {
          padding-top: 0.2rem;
        }
        .form-content {
          padding-top: 1rem;
          padding-left: 1rem;
        }
    
        .signature-container {
          column-gap: 15rem;
        }
    }
}
   
  #casesheet, #casesheet * {
    visibility: visible;
  }

  #casesheet {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }