.view-section{
    width: 100%;
    height: 100%;
    background: #FBF8FF;
    
    .view-file-header{
        background:#FFF;;
        height: 5.625rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        border-bottom: 1px solid #BBB;
        z-index: 10;

        
            .patient-info-header{
                display:flex;
                align-items: center;
                padding-left: 1rem;
            }

            .back-arrow{
                width: 4.375rem;
                height: 2.5rem;
                margin-left: 1.25rem;
                border-radius: 50px;
                background: #FBF9FF;
                cursor: pointer !important;
            }

            .casesheet-button{
                display: inline-flex;
                min-width: fit-content;
                height: 3.125rem;
                align-items: center;
                color: #FFF;
                padding: 1.2rem;
                font-size: 1.125rem;
                font-style: normal;
                font-family: 'poppins';
                font-weight: 300;
                line-height: normal;
                border-radius: 100px;
                border: 1px solid var(--primary-purple, #A163F1);
                background: #A163F1;
                box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08), 0px 4px 40px 0px rgba(255, 255, 255, 0.12);
                
            }

            .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
                width: 10rem;
                border-radius: 4px;
                border: 0.8px solid #A163F1;
                height: 4rem;
                margin-left: 1.875rem;
            }
            
            .update{
                display: flex;
                justify-content: baseline;
                align-items: center;
                //margin-left: 1rem;

                .update_time{
                    color: #707070;
                    font-size: 1.125rem;
                    font-weight: 350;
                    margin-left: 1rem;
                }
            }

            @media (min-width: 1370px) and (max-width: 1500px){
                
                .button{
                    margin-left: 3.125rem;
                }
            
                .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
                    margin-left: 3.75rem;
                }
            }
    }

    .basic-view-header{
        background:#FFF;;
        height: 5.625rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        border-bottom: 1px solid #BBB;
        z-index: 10;

        .left-header-section{
            display:flex;
            align-items: center;
            padding-left: 2rem;

             .back-arrow{
                width: 4.375rem;
                height: 2.5rem;
                border-radius: 50px;
                background: #FBF9FF;
                cursor: pointer !important;
            }
        }

        .right-header-section{
            display: flex;
            justify-content: baseline;
            align-items: center;
            margin-left: 1rem;

            .update_time{
                color: #707070;
                font-size: 1.0625rem;
                font-weight: 350;
                margin-right: 5rem;
                margin-left: 1rem;
            }
        }

        .basic-reports-text{
            margin-left: 1rem;
            color: #777;
            font-size: 1.125rem;

            .patient-text{
                color: #979797;
                font-size:1.125rem;
            }
        }
    }
}

.view-section-sidebar{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    display:flex;
    flex-direction: column;
    gap:0.6rem;
    width:19%;
    height:100vh;
    border-radius:2px;
    background: #FEFEFF;
    box-shadow: 2px 3px 40px 0px rgba(23, 23, 23, 0.06), 1px 4px 20px 0px rgba(255, 255, 255, 0.09);
    padding-left: 1rem;
    padding-top: 1.5rem;
    margin-top: 5.6875rem;
   
    .inactive-text{
        display:flex;
        align-items: center;
        gap:0.6rem;
        width:99%;
        height: 2.8rem;
        color: #9B9B9B;
        font-size: 0.9375rem;
        font-weight: 200;
        margin-left: 2rem; 
    }

    .active-text{
        display:flex;
        align-items: center;
        width:99%;
        height:3.25rem;
        border-radius: 12px;
        background: #F7F0FF;
        color: #171717;
        font-size: 1rem;
        font-weight: 600; 

        p{
          padding-left: 2rem;
        }
    }

    .inactive-text, .active-text:hover{
        cursor:pointer !important;

    }

    .inactive-text:hover{
        color:#656565;
        font-weight: 300;
    }

    .viewfile-footer{
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:1rem;
        left: 0px;
        top: 80%;
        width: 100%;

        .date-footer{
            font-family: Poppins;
            font-size: 0.6875rem;
            font-weight: 300;
            line-height: 1rem;
            color: #707070;
        }
    }   
}

.scroll-component{
   padding-top: 7.5rem;
   display:flex;
   flex-direction: column;
   gap:2rem;
   
   .reports-div{
        display: flex;
        align-items: center;
        margin-left:26%;
    
        .reports{
            margin-right: 0.3125rem;
            color: #777;
            font-size: 1rem;
            font-weight: 300;
        }
        .name{
            color: #A163F1;
            font-size: 1rem;
            font-weight: 400; 
        }
   }   

   .osi-reports-div{
    display: flex;
    align-items: center;
    margin-left:22%;

        .osi-reports{
            margin-right: 0.3125rem;
            color: #777;
            font-size: 1rem;
            font-weight: 300;
        }
        .osi-name{
            color: #A163F1;
            font-size: 1rem;
            font-weight: 400; 
        }
    }  
    
}

.horizontal-line{
    display:flex;
    align-items: center;
    width:90%;
    opacity:0.3;
    margin-top: 1rem;
    stroke-width: 1px;
    stroke: #D6D6D6;
}

.janitri-logo{
    padding-top: 1rem;
    width:5.75rem;
    height:1.875rem;

}

.view-sidebar-basic{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    display:flex;
    flex-direction: column;
    gap:0.6rem;
    width:19%;
    height:100vh;
    border-radius:2px;
    background: #FEFEFF;
    box-shadow: 2px 3px 40px 0px rgba(23, 23, 23, 0.06), 1px 4px 20px 0px rgba(255, 255, 255, 0.09);
    padding-top: 1.7rem;
    margin-top: 5.6875rem;

    .active-text-basic{
        display:flex;
        align-items: center;
        gap:0.6rem;
        height:80px;
        border-radius: 12px;
        border: 1px solid #A464F7;
        background: #F4EDFF;
        color: #171717;
        font-size: 1rem;
        font-weight: 600; 

        .nav-icon{
            margin-left: 2.3rem;
        }
    }
    .inactive-text-basic{
        display:flex;
        align-items: center;
        gap:0.6rem;
        height: 3.25rem;
        color: #171717;
        font-size: 1rem;
        font-weight:500;
        margin-left: 2.4rem;
    }
}

.side-tab{
    height:100%;
    padding-bottom:1rem;

    .sub-tabs{
        display:flex;
        flex-direction: row;
        padding-top: 2rem;

        .sub-tab{
            display: flex;
            align-items: center;
            justify-content: center;
            width:100%;
            padding-right: 1rem;
            padding-bottom: 1.5rem;
        }
        
        .active-device-text{
            width:100%;
            color: #171717;
            font-size: 1.125rem;
            font-weight: 500;
            border-bottom: 3px solid #A163F1;
    
        }
    
        .inactive-device-text{
            width:100%;
            color: #6C6C6C;
            font-size: 1.125rem;
            font-weight: 300;
        }
    }
}

.timestamp-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:2rem;
    padding-top:1.5rem;
    padding-bottom:1.5rem;
}

.active-timestamp{
    background: rgba(243, 243, 243, 0.52);
    color: #171717;
    font-size: 1rem;
    font-weight: 300;
}

.inactive-timestamp{
    color: #B2B2B2;
    font-size: 1rem;
    font-weight: 300;
}

.casesheet-container{
    display:flex;
    flex-direction: column;
}

.update-icon{
    cursor:pointer !important;
}


/* Screen height between 600px and 700px */
@media (min-height: 37.5rem) and (max-height: 43.75rem) { 
    html {
        font-size: 14px;
    }
  
}

/* Screen height between 700px and 800px */
@media (min-height: 43.75rem) and (max-height: 50rem) { 
    html {
        font-size: 15px;
    }
    
}

/* Screen height between 800px and 900px */
@media (min-height: 50rem) and (max-height: 56.25rem) { 
    html {
        font-size: 16px;
    }
   
}

/* Screen height between 900px and 1000px */
@media (min-height: 56.25rem) and (max-height: 62.5rem) { 
    html {
        font-size: 17px;
    }

}

/* Screen height between 1000px and 1100px */
@media (min-height: 62.5rem) and (max-height: 68.75rem) { 
    html {
        font-size: 18px;
    }
    
}

/* Screen height above 1100px */
@media (min-height: 68.75rem) { 
    html {
        font-size: 19px;
    }
    
}







