// .css-m9glnp-MuiPaper-root-MuiDialog-paper {
//     background: #FBF8FF !important;
// }

// .myDialogWrapper .css-m9glnp-MuiPaper-root-MuiDialog-paper {
//     background: #FBF8FF !important;
// }

.device-file-header{
    background:#FFF;;
    height: 90px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    border-bottom: 1px solid #BBB;
    z-index: 10;

    .button{
        display: inline-flex;
        height: 40px;
        align-items: center;
        color: #FFF;
        padding: 20px 25px;
        font-size: 18px;
        font-style: normal;
        font-family: 'poppins';
        font-weight: 300;
        line-height: normal;
        border-radius: 100px;
        border: 1px solid var(--primary-purple, #A163F1);
        background: #A163F1;
        box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08), 0px 4px 40px 0px rgba(255, 255, 255, 0.12);
        margin-right: 2.5rem;
        opacity: 20%;
    }

    .patient-information{
        display:flex;
        align-items: center;
        padding-left: 1rem;
       

        
        img{
            width: 70px;
            height: 40px;
            margin-left: 10px;
            border-radius: 50px;
            background: #FBF9FF;
        }

        

        .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
            width: 160px;
            border-radius: 4px;
            border: 0.8px solid #A163F1;
            height: 43px;
            margin-left: 30px;
        }
        
        .update{
            display: flex;
            justify-content: baseline;
            align-items: center;

            .update_time{
                color: #707070;
                font-size: 18px;
                font-weight: 350;
                margin-left: 1rem;
            }
        }

        @media (min-width: 1370px) and (max-width: 1500px){
            
            .button{
                margin-left: 50px;
            }
        
            .update{
                margin-left: 60px;
            }
        
            .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
                margin-left: 60px;
            }
        }
    }
    
   
}

.device-section-sidebar{
    
    overflow-y: auto;
    overflow-x: hidden;
    width:20%;
    height:100vh;
    border-radius: 2px;
    background: #FEFEFF;
    box-shadow: 2px 3px 40px 0px rgba(23, 23, 23, 0.06), 1px 4px 20px 0px rgba(255, 255, 255, 0.09);
    padding-top: 1.75rem;

    .date-footer{
        position: absolute;
        text-align: center;
        font-family: Poppins;
        font-size: 11px;
        font-weight: 300;
        line-height: 16.5px;
        color: #707070;
        top:98%;
    }

    .nst-traces{
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #171717;
        text-align: center;
        margin: 20px 0px;
    }
   
    .inactive-text{
        display:flex;
        align-items: center;
        justify-content: space-around;
        width:100%;
        //height: 50px;
        padding: 20px 0px 20px 0px;
        color: #9B9B9B;
        font-size: 16px;
        font-weight: 300;
               
    }
    .active-text{
        display:flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        padding: 20px 0px 20px 0px;
        //height: 50px;
        border-radius: 16px;
        background: #F7F0FF;
        color: #171717;
        font-size: 16px;
        font-weight: 600; 
        

        
    }

    .inactive-text, .active-text:hover{
        cursor:pointer !important;

    }

    .inactive-text:hover{
        color:#656565;
        font-weight: 400;
    }

    .logo{
        display:flex;
        align-items: center;
        width:85px;
        height:30px;
        padding-left:33%;
        padding-top:3%;
    }

    
}


.graph-components{
    display: flex;
    margin-top: 90px;
    position: relative;
    

    .right-graph-component{
        margin-left: 5%;
        width:70%;

        .reports-div{
            display: flex;
            align-items: center;
            margin-top: 40px;
            margin-bottom: 20px;
            //padding-left: 8%;
        
            .reports{
                margin-right: 5px;
                color: #777;
                font-size: 17px;
                font-weight: 300;
            }
            .name{
                color: #A163F1;
                font-size: 17px;
                font-weight: 400; 
            }
       }   
    }
}

.no-data{
    display: flex;
    justify-content: center;
    margin-top: 8%;
}

.keyar-echo-div{
    
    background-color: #FFFFFF;
    width: 100%;
    height: 500px;
    overflow-y: hidden;
    border-radius: 12px;


    .upper-div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 40px;
        border-bottom: 1px solid #D8E7E9;

        .avg-fhr{
            font-weight: 400;
            font-size: 24px;
            line-height: 36px;
        }

        .duration{
            font-weight: 400;
            font-size: 24px;
            line-height: 36px;
        }
        
    }

    .audio{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;

        .fhr-key{
            font-weight: 400;
            font-size: 40px;
            line-height: 60px;
            margin: 20px 10px;
        }

        .fhr-value{
            font-weight: 400;
            font-size: 90px;
            line-height: 60px;
            margin: 20px 10px;

        }
    }

}

.player{

    position: relative;

    .player-range{
        width: 600px;
        height: 50px
    }

    .play-pause{
        border: none;

    }

   

    
    
}

.play-button-div{
    display: flex;
    justify-content: center;
    align-items: center;
}

button{
    border: none;
    background: #fff; 
}

.pause-text{
    color: #ADADAD;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
}

//SI Graph CSS
.si-graph-section{
    
   // position: fixed;
    margin-right:2%;
    display:flex;
    
    

    .si-graph-container{
        padding-top: 2rem;
        border-radius: 12px;
        width: 75%; 

            .graph-header{
                display:flex;
                flex-direction: row;
                justify-content: flex-end;
                padding:0 3%;
                align-items:center;
                background-color: #FFF;
                padding-top: 0.8rem;

    
                .axis-text{
                    color: #A163F1;
                    font-size: 17px;
                    font-weight: 400;
                }
    
                .axis-value{
                    color: #030303;
                    font-size: 17px;
                    font-weight: 400;
                }

                .graph-info{
                    display:flex;
                    flex-direction: row;
                    justify-content: first baseline;
                    align-items: center;
                    gap:1.5rem;
                }
            }

    }

    .si-card-section{
        //position: absolute;
        //width:19.5%;
        margin-top: 2rem;
        margin-left: 2rem;
        background-color: #FFF;
        padding-top: 1rem;
        border-radius: 4px;
        background: #FEFEFF;
        box-shadow: 0px 2px 40px 2px rgba(18, 18, 18, 0.06), 3px 0px 40px 0px rgba(255, 255, 255, 0.25);
        
        
        .patient-text{
            padding-left: 1.5rem;
            color: #171717;
            font-size: 18px;
            font-weight: 500;
        }

        .si-inactive-card{
            display:flex;
            flex-direction: column;
            gap:1rem;
            border-radius: 9.67px;
            border: 1px solid #C8C8C8;
            background: #FEFEFF;
            margin:1rem; 
            overflow-y: auto;
            overflow-x: hidden;
    
            .card-info{
                display:flex;
                flex-direction: column;
                padding: 1rem;
            }

            .card-data{
                display:flex;
                flex-direction: row;
                padding-top:0.5rem;
            }

            .card-values{
                display:flex;
                flex-direction: column;
                gap:0.5rem;
                text-align: center;
            }
        }

        .si-active-card{
            display:flex;
            flex-direction: column;
            gap:1rem;
            border: 1px solid #A163F1;
            //background: #A163F1;
            margin:1rem; 
            //color:white;
            border-radius: 9.67px;
            border: 1px solid #A163F1;
            box-shadow: 2.417px 2.417px 32.232px 0px rgba(255, 255, 255, 0.25), 2.417px 2.417px 24.174px 0px rgba(18, 18, 18, 0.14);
            overflow-y: auto;
            overflow-x: hidden;
            
            .card-info{
                display:flex;
                flex-direction: column;
                padding: 1rem;
                
            }
            .card-data{
                display:flex;
                flex-direction: row;
                padding-top:0.5rem;
            }
            .card-values{
                display:flex;
                flex-direction: column;
                gap:0.5rem;
                text-align: center;
            }
        
        }
            
           
      }
    }

.hr-line{
    width:96%;
    border-radius: 4px;
    border-top: 1px solid #EDF2F7;
    background: #FEFEFF;

}



