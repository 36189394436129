.central-monitor-error{
    height: 92vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    overflow-y: auto;

    .image-div{
        opacity: 0.8;
        .cm-error-image{
            width: 46.8rem;
            height: 22.06rem;
        }
        margin-bottom: 6.25;

    }

    .no-live-test-text{
        color: #FFF;
        font-size: 2rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-family: 'Poppins', sans-serif;
    }

    .or-text{
        color: #A163F1;
        font-size: 1.625rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        font-family: 'Poppins', sans-serif;
    }

    .retry-text{
        color: #808080;
        font-size: 2rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-family: 'Poppins', sans-serif;
    }
}

/* Screen height between 600px and 700px */
@media (min-height: 37.5rem) and (max-height: 43.75rem) { 
    html {
        font-size: 14px;
    }
   
}

/* Screen height between 700px and 800px */
@media (min-height: 43.75rem) and (max-height: 50rem) { 
    html {
        font-size: 15px;
    }
   
}

/* Screen height between 800px and 900px */
@media (min-height: 50rem) and (max-height: 56.25rem) { 
    html {
        font-size: 16px;
    }
    
}

/* Screen height between 900px and 1000px */
@media (min-height: 56.25rem) and (max-height: 62.5rem) { 
    html {
        font-size: 17px;
    }
   
}

/* Screen height between 1000px and 1100px */
@media (min-height: 62.5rem) and (max-height: 68.75rem) { 
    html {
        font-size: 18px;
    }
   
}

/* Screen height above 1100px */
@media (min-height: 68.75rem) { 
    html {
        font-size: 19px;
    }  
}
