@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap');

html{
    margin: 0 !important;
    padding: 0;
    height: 100%;
    
}
body{
    margin: 0 !important;
    background-color: #FBF8FF;
    font-family: 'Poppins', sans-serif;
    height: 100%;
}