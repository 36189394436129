.analytics{
    display:flex;
    flex-direction: column;
    gap:1rem;
    margin-left: 17%; /* Width of the sidebar */
    height:100%;

}

.analytics-page{
    padding: 1.25rem;
    height:100%;
    padding-right: 2.8rem;
}

.analytics-legend {
    text-align: left;
    font-size: 1.375rem;
    line-height: 1.6875rem;
    letter-spacing: 0px;
    color: #A163F1;
    margin-left: 1.2rem;
    padding: 0rem 3rem;
    padding-top: 0.5rem;
}

.delivery-section{
    background: #fff;
    width: 100%;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)!important;
    border-radius: 12px;
    padding:1rem 0rem;

        .delivery-column-grid{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap:1.4rem;
        }
}


.first-delivery-column{
    display:flex;
    flex-direction: row;
    padding:1rem;
    align-items:flex-end;
    justify-content: space-evenly;
    padding-top: 2rem;
}

.delivery-column-grid{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap:1.4rem;
}

.alignment{
    display:flex;
    align-items: center;
    justify-content: center;
}

.delivery-center{
    display: flex;
    flex-direction: column;
    gap:1rem;
    align-items: center;
    justify-content: center;

    .delivery-circle-container {
        display: flex;
        align-items: center;
        justify-content: center;
        
        .deliveries-circle {
            border-radius: 50%;
            background: linear-gradient(40deg, #ECEDFD, #dac6f5, #FFFFFF);
            padding: 1.5rem;

            .inner-deliveries-circle{
                padding: 0.5rem;
            }
        }
    }

}

.delivery-form-card{
    border: 0.6px solid #A163F1;
    border-radius: 12px;
    margin: auto 1rem;
    padding: 1rem 4rem;
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: flex-start;
    gap:0.7rem;

}

.analytics-info-card{
    background: #FFFFFF;
    border: 0.6px solid #A163F1;
    border-radius: 12px;
    margin: auto 0.5rem;
    display:flex;
    flex-direction: row;
    gap:1rem;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
    padding-right: 2rem;
}

.analytics-card-figure{
    font-size: 1.9rem;
    line-height: 3rem;
    color: #000000;
}

.analytics-card-text{
    font-size: 1rem;
    line-height: 1.25rem;
    color: #000000;
}

.family-planning-section{
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    align-items: center;
    justify-content: flex-start;
    gap:2rem;
}

.family-palnning-div{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding:1rem;

    .family-planning-card{
        background: #FFFFFF;
        border: 0.6px solid #A163F1;
        border-radius: 12px;
        margin: auto 0.5rem;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap:1rem;
        padding:2rem;
    }
}

.family-column{
    display: flex;
    flex-direction: column;
}

.family-circle-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top:1rem;
}

.family-circle {
    border-radius: 50%;
    box-shadow: -8px 11px 10px #00000029;
    background: linear-gradient( #DBF3FE, #DBF3FE);
    padding: 1.2rem;
    padding-top: 1rem;
}

.inner-family-circle {
    box-shadow: inset -8px 11px 10px #00000029;
    background: #fff;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height:15.625rem;
    width: 15.625rem;
    padding:1rem;
}

.immunization-progress-container{
    display:grid;
    grid-template-columns: repeat(3,1fr);
    align-items: center;
    row-gap:3rem;
    padding:1rem;
}

.antenatal-progress-container{
    display:grid;
    grid-template-columns: repeat(3,1fr);
    align-items: center;
    column-gap:3rem;
    row-gap: 1rem;
    padding:1rem 2rem;
}

.progress-bar {
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap:3rem;
}

.outer-linear-progress {
    height: 1.25rem;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: -2px 3px 6px #00000029;
    padding: 0.6rem;
}

.steroids-death-div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap:2rem;
}


.neonatal-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.7rem; 
    padding: 1.5rem;
  }
  
  .neonatal-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:1rem;
  }
  
  .neonatal-square {
    width: 6.25rem;
    height: 6.25rem; 
    border: 5px solid #FF8E8F;
    border-radius: 50%;
  }
  
  .inner-neonatal-square {
    padding:2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .child-birth-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 3rem;
    gap: 2rem; 
    padding: 2rem;
  }
  
  .child-birth-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:1rem;
  }
  
  .child-birth-square {
    width: 6.25rem;
    height: 6.25rem; 
    border: 1px solid #FD516C;
    // 4F1787, FF8FB1
    background-color: #f9f1f4;
    border-radius: 50%;
  }
  
  .inner-neonatal-square {
    padding:2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .inner-sex-ratio-circle {
    box-shadow: inset -8px 11px 10px #00000029;
    background: #fff;
    height: 12.5rem;
    width: 12.5rem;
    line-height: 6.25rem;
    border-radius: 50%;
    padding:2rem;
}

.child-birth-label{
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: center;
}

.sex-ratio-circle{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:2.5rem;
    gap:2rem;
}

.maternal-death-column{
    display:flex;
    flex-direction: row;
    padding:1rem;
    align-items:center;
    justify-content: space-evenly;
    padding-top: 3rem;
}

.covid-figure {
    font-size: 3rem;
    line-height: 4rem;
    color: #000000;
}

.covid-text {
    font-size: 1rem;
    line-height: 1.5rem;
    color: #000000; 
    text-align: center;
}

.search-filter-div{
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    margin: 5rem 0px 0px 0px ;
    background: #FFF;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.12), 0px 4px 40px 0px rgba(255, 255, 255, 0.25);
    margin-right: 2.5rem;
    margin-left: 1rem;
    margin-top: 7.5rem;
}

.container{
    display: flex;
    flex-direction: column;
    width:100%;
    margin:0 0.625rem;
    padding-top: 0.625rem;
    padding-bottom:0.8rem;
}

.date-filter-container{
    display:flex;
    flex-direction: row;
    gap: 3rem;

    .date-picker{
        display: flex;
        flex-direction:column;
        gap: 1rem;
        width:16.875rem;
        max-height: max-content;

        .text1{
            color: #171717;
            font-size: 1rem;
            font-weight: 500;
        }

        .css-i4bv87-MuiSvgIcon-root {
            fill: #B789F3;
            
        }
    }
}

.filter-button-div{
    display: flex;
    margin-left:1rem;
}

.active-filter-button{
    border-radius: 500px;
    background: #DABDFF;
    border: 0.5px solid #A163F1;
    padding: 0.8rem 2.2rem; 
    border: none;
    color: #171717;
    font-size: 1rem;
    font-family: poppins;
    font-weight: 300;
    text-transform: capitalize;
    margin:  0.3125rem 0.3125rem 1.875rem 1.875rem;
    cursor: pointer;
}

.inactive-filter-button{
    border-radius: 500px;
    border: 0.5px solid #A163F1;
    padding: 0.8rem 2.2rem; 
    background: #F9F9F9;
    color: #707070;
    font-size: 1rem;
    font-family: poppins;
    font-weight: 300;
    text-transform: capitalize;
    margin: 0.3125rem 0.3125rem 1.875rem 1.875rem;
    cursor: pointer;
}

.filter-container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap:3rem;
    margin-left: 2.5rem;
}


.apply-filter-container{
    display:flex;
    justify-content: flex-end;
    margin-top:1.5rem;
    margin-right:3rem;
}


.apply-button{
    border-radius: 50px;
    border: 1px solid #A163F1;
    background-color:#A163F1;
    align-items: center;
    width: 12.5rem;
    height: 3.4275rem;;
    color:white;
    justify-content: center;
    font-size:  1.125rem;
    font-weight: 300;
    font-family: poppins;
    text-transform: capitalize;
    margin-bottom:1.5rem;
    cursor: pointer !important;
}

.inactive-apply-button{
    border-radius: 50px;
    border: 1px solid  #9B9B9B;
    align-items: center;
    width: 12.5rem;
    height: 3.4275rem;
    color:#9B9B9B;
    justify-content: center;
    font-size: 1.125rem;
    font-weight: 300;
    font-family: poppins;
    text-transform: capitalize;
    margin-bottom:2.2rem;
    cursor: pointer !important;
}

.selected-filters{
    margin: 1.25rem 1.875rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem; /* Adjust the space between chips */
    max-width: 100%; /* Ensure the container does not exceed the width of its parent */
    overflow: hidden; /* Hide the overflow */
}

.filter-button{
    display: flex;
    border-radius: 50px;
    align-items: center;
    gap:0.5rem;
    justify-content: flex-start;
    padding: 0.85rem 3.3rem;
    cursor: pointer !important;


    .filter-icon{
        width: 1.625rem;
        height: 1.625rem;
    }
}

.filter-text{
    color: #666;
    font-family: Poppins;
    font-size: 1.125rem;
}

.search-filter-analytics{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding:0.7rem;
    padding-right: 3rem;
}

.down-arrow{
    width:1.25rem;
    height:0.9375rem;
}

.analytic-image{
    width:4.5rem;
    height:4.5rem;
}

.delivery-image{
    width:6rem;
    height:6rem;
}


/* Screen height between 600px and 700px */
@media (min-height: 37.5rem) and (max-height: 43.75rem) { 
    html {
        font-size: 14px;
    }
   
}

/* Screen height between 700px and 800px */
@media (min-height: 43.75rem) and (max-height: 50rem) { 
    html {
        font-size: 15px;
    }
   
}

/* Screen height between 800px and 900px */
@media (min-height: 50rem) and (max-height: 56.25rem) { 
    html {
        font-size: 16px;
    }
    
}

/* Screen height between 900px and 1000px */
@media (min-height: 56.25rem) and (max-height: 62.5rem) { 
    html {
        font-size: 17px;
    }
   
}

/* Screen height between 1000px and 1100px */
@media (min-height: 62.5rem) and (max-height: 68.75rem) { 
    html {
        font-size: 18px;
    }
   
}

/* Screen height above 1100px */
@media (min-height: 68.75rem) { 
    html {
        font-size: 19px;
    }  
}



