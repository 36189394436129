.osi-info-section{
    width:100%;
    margin-left:22%;
    display:flex;
    flex-direction: row;
    gap:3rem;
    overflow-x: hidden;
    
    .loading-div{
        width:80%;
        margin-top: 7.5rem;
    }
  
    .graph-section{
        width:54%;
        position: fixed;

        .osi-reports-div{
            display: flex;
            align-items: center;
            margin-left:1rem;
            margin-bottom: 1.2rem;
            
            .reports{
                margin-right: 0.3125rem;
                color: #777;
                font-size: 1.125rem;
                font-weight: 300;
            }

            .name{
                color: #A163F1;
                font-size: 1.125rem;
                font-weight: 400; 
            }
        }

        .osi-info-header{
            border-radius: 9px;
            background: #FFF;   
            padding-bottom: 0.5rem;
            margin-top: 0.5rem;

            .text-1{
                color: #666;
                font-size: 1.125rem;
                font-weight: 300;
                padding-left:1.5rem; 
                padding-top: 0.5rem;
            }

            .time-text{
                font-weight:bold ;
                color:#171717;
            }
        }
        
        .graph-container{
            padding-top: 2rem;
            border-radius: 12px; 

            .graph-header{
                display:flex;
                flex-direction: row;
                justify-content: space-between;
                padding:0 3%;
                align-items:center;
                background-color: #FFF;
                padding-top: 0.8rem;

                .graph-heading{
                    color: #030303;
                    font-size: 1.5rem;
                    font-weight: 500;
                }
    
                .axis-text{
                    color: #A163F1;
                    font-size: 1.0625rem;
                    font-weight: 400;
                }
    
                .axis-value{
                    color: #030303;
                    font-size: 1.0625rem;
                    font-weight: 400;
                }

                .graph-info{
                    display:flex;
                    flex-direction: row;
                    justify-content: first baseline;
                    align-items: center;
                    gap:1.5rem;
                }
            }

        }  
        
    }

    .card-section{
        position: absolute;
        width:19.5%;
        margin-top: 2.8rem;
        margin-left: 56%;
        background-color: #FFF;
        padding-top: 1rem;
        border-radius: 4px;
        background: #FEFEFF;
        box-shadow: 0px 2px 40px 2px rgba(18, 18, 18, 0.06), 3px 0px 40px 0px rgba(255, 255, 255, 0.25);
        
        
        .patient-text{
            padding-left: 1.5rem;
            color: #171717;
            font-size: 1.125rem;
            font-weight: 500;
        }

        .card{
            display:flex;
            flex-direction: column;
            gap:1rem;
            border-radius: 12px;
            background: #F1F1F1;
            margin:1rem; 
            color:#777;
            overflow-y: auto;
            overflow-x: hidden;
    
            .card-info{
                display:flex;
                flex-direction: column;
                padding: 1.5rem;
            }

            .card-data{
                display:flex;
                flex-direction: row;
                padding-top:1rem;
            }

            .card-values{
                display:flex;
                flex-direction: column;
                gap:0.5rem;
                text-align: center;
            }
        }

        .active-card{
            display:flex;
            flex-direction: column;
            gap:1rem;
            border-radius: 12px;
            border: 1px solid #A163F1;
            background: #A163F1;
            margin:1rem; 
            color:white;
            overflow-y: auto;
            overflow-x: hidden;
            
            .card-info{
                display:flex;
                flex-direction: column;
                padding: 1.5rem;
            }

            .card-data{
                display:flex;
                flex-direction: row;
                padding-top:1rem;
            }
            
            .card-values{
                display:flex;
                flex-direction: column;
                gap:0.5rem;
                text-align: center;
            }
        
        }
            
           
      }
    }

.hr-line{
    width:96%;
    border-radius: 4px;
    border-top: 1px solid #EDF2F7;
    background: #FEFEFF;

}


