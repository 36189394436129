
.blur-dashboard{
    opacity:0.4;
}

.dashboard{
    opacity: 1;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
}

.dashboard-header{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: white;
    box-shadow: 0px 4px 40px 0px rgba(55, 55, 55, 0.12), 0px 4px 40px 0px rgba(255, 255, 255, 0.20);
    margin-left: 17%;
    position: fixed;
    width: 83%;
    height: 5rem;
    z-index:10;
    border-bottom: 0.5px solid #B481F5;
    margin-right:5rem;

    .update-div{
        display:flex;
        flex-direction: row;
        align-items: center;
        gap:1rem;

        .update-text{
            color:#707070;
            font-size:1rem;
            font-weight:400;
            text-align:center;
        }
    }

    .notification-bell{
        margin-right:4rem;
        position: relative;
        display: inline-block;

        .notification-badge {
            position: absolute;
            bottom: 1.875rem; 
            left: 1.25rem; 
            background-color: #FF4949;
            color: white;
            border-radius: 50%;
            padding: 0.1875rem 0.5rem;
            font-size: 0.625rem; 
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
          }
    }

    .component{
        margin-top: 5.625rem;
    }
}

.back-to-top{
    z-index:1000;
    position: fixed;
    bottom:5rem;
    right:2.5rem;
    padding: 0.7rem 1.3rem; 
    background-color: white;
    border-radius: 500px;
    border: 1px solid #A163F1;
    color:#A163F1;
    cursor: pointer !important;

    
}

.show-back-to-top{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:1rem;
}

.hide-back-to-top{
   display:none
}

.bell-icon{
    cursor:pointer !important;
}