.alert-modal{
    width:32%;
    height:100vh;
    position: absolute;
    margin-left: 68%;
    background: white;
    border: 1px solid #ccc;
    z-index: 1000;
    
    .no-alerts{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap:4rem;
        margin:auto;

        .no-alerts-text{
            color: #7A7A7A;
            font-size: 22px;
            font-weight: 500;
        }
    }
}

.notification-item{
    display:flex;
    flex-direction: column;
   // align-items: center;
   justify-content: center;
   gap:0.5rem;
   margin-top:1rem;
   margin-bottom: 1rem;

   .alert-text{
    color: #616161;
    font-size: 14px;
    font-weight: 400;
   }
}

.line{
    width:90%;
    stroke-width: 1px;
    stroke: #D7E4E8;
    opacity: 0.2;
}

.alert-date-text{
    color: #616161;
    font-size: 12px;
    font-weight: 300;
}

.alert-details{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.alert-close-button{
    cursor:pointer !important;
}

.notification-header{
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-around;
    gap:9rem;

    .header-text{
        color: #000;
        font-size: 24px;
        font-weight:500;
    }
}

.notification-div{
    display:flex;
    flex-direction:row;
    align-items: center;
    gap:1.5rem;
    margin-right: 2rem;
    margin-left: 2rem;
    cursor : pointer !important;
}  

.alert-note{
    font-size:12px;
    color:rgb(105, 105, 105);
    padding:0rem 4rem;
    
}


