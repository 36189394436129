.interpretation-box{
    display: flex;
    flex-direction: column;
    width:25%;
}

.interpretation-block{
    height: 100%;
    margin-top: 20px;
    margin-right: 10px;
    box-shadow: none !important;
    border: 1px solid #6D0E81 !important; 
    opacity: 1;
    margin-left: 20px;
    padding:0.5rem; 
    margin-right:10px;
}

.notes-box{
    margin-top: 20px;
    margin-right: 10px;
    box-shadow: none !important;
    border: 1px solid #6D0E81 !important; 
    opacity: 1;
    margin-left: 20px;
    padding:0.5rem 4rem; 
    margin-right:1rem;
    margin-bottom: 3rem;
}

.basic-detail-keys{
    font-size: 12px;
    flex: 1;
    font-weight: bold;
}

.basic-detail-value{
    font-size: 12px;
    margin-left: 5px;
    padding-left: 10px;
    width: 200px;
    margin-top: 2px;
}

.basic-detail-rows{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

// #keyar_graph_document_header{
//     margin: 30px 0 0 40px;
//     width: 90%;
// }
p{
    margin: 0;
}

.nst-header {
  padding: 2px 0px 2px 5px;
  display: flex;
}

.nst-title{
    font-size:11px;
}

.fhr_rows{
    display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
    gap:2px;
    align-items: first baseline;
    justify-content: center;
    width: 100%;
    font-weight: bold;  
    margin-top: 2px;
    padding-left:0.5rem; 
}

.interpretation-header {
    margin: 1rem;
    font-size: 12px;
    color: #6D0E81;
    font-weight: bold;
    text-align: center;
    height:100%;

}

.fhr-keys{
    font-size: 10px;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}

.fhr-value{
    font-size: 10px;
    margin-left: 5px;
    padding-left: 10px;
    width: 50%;
}

.basic-detail-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.janitri-logo{
    width: 92px;
    height: 30px;
}

.grid-template{
    margin: 10px 50px;
}

.basic-detail{
    margin: 0px;
}

.basic_detail_row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.basic_detail_name{
    font-weight: 700;
    font-size: 12px;
    margin-left: 10px;
}

.basic_detail_field{
    font-size: 12px;
    margin-left: 10px;
}

.janitri_nst_logo{
    width: 92px;
    height: 30px;
    margin-left: 5px;
    margin-top: 15px;
}

.nst-hr{
    border: 0.5px solid #6D0E81 !important;
    width: 100%; 
}

// @page {
//     size: A4;
//     /* margin: 1rem 0rem; */
//     margin-top: 9mm;
//     margin-bottom: 8mm;
//     margin-left: 7mm;
//     margin-right: 7mm;
// }

// @media print {
//     html,body {
//         height:100vh; 
//         margin: 0 !important; 
//         padding: 0 !important;
//         overflow: hidden;
//     }
//     body * {
//     /* font-size: 32px; */
//     font-family: Arial, Helvetica, sans-serif;
//     page-break-inside: avoid;
//     -webkit-print-color-adjust: exact;
//     /* width: 780px; */
//     /* overflow: hidden; */
//     }
//     #download-nst-report, #download-nst-report *{
//         visibility: visible;
//     }
// }


.keyar-layout{
    display: flex;
    // height: 100%;
    // width: 100%;
    //justify-content: space-around;
    
}

.nst-report{
    background-color: #fff;
    padding: 20px 0px 40px 0px;
}

.reports-detail-div{
    display: flex;
    align-items: center;
    justify-content: center;
   

    .reports-detail-text{
        margin-right: 5px;
        color: #777;
        font-size: 16px;
        font-weight: 300;
    }
    .device-name{
        color: #A163F1;
        font-size: 16px;
        font-weight: 400; 
    }
}   

#nst-report{
    display: flex;
    flex-direction: row;
    align-items: first baseline;
    justify-content: space-between;
    padding: 1rem;
}

.keyar-layout-button-div{
    background-color: #A163F1;
    padding:0.5rem 1.5rem;
    border-radius: 50px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    float:right;
    gap:0.3rem;
    margin-top: 20px;
    cursor: pointer !important;
}

.print-nst-button{
    background: none;
    color: white;
}

.print-settings{
    display:flex;
    flex-direction: row;
    align-items: last baseline;
    justify-content: center;
    gap:1.5rem;

}

