.back-button{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    margin-left: 0.6rem;
    margin-top: 0.3rem;
    cursor: pointer;
    
    .back-dark{
        color: #FEFCFF;
        font-size: 1.4rem;
        font-weight: 400;
    }

    .back-light{
        color: black;
        font-size: 1.4rem;
        font-weight: 400;
    }

    .back-icon{
        width: 15px;
        height: 25px;
        margin-right: 0.6rem;
    }
}

.search-div{
    margin-right: 20px;
    position: relative;

    .search{
        border-radius: 10px;
        border: 1px solid #7A7A7A;
        color: #FEFCFF;
        width: 300px;
        height: 20px;
        background-color: #2C2C2C;
        color: #7A7A7A;
        font-size: 18px;
        font-weight: 400;
        padding: 10px;
    }
    .search-image{
        position: absolute;
        right: 40px;
        top: 10px;
        width: 20px;
        height: 20px;
    }
}


.loader{
    position: relative;
    display: flex;
    justify-content: center;
    top:40%; 
}

.loading-background{
    width:100%;
   
    .loading-div{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:0.7rem;
        margin-top: 7rem;
        top:80%; 
       
        .loading{
            width: 40px;
            height: 40px;
            border: 5px solid #A163F1;
            border-bottom-color: transparent;
            border-radius: 50%;
            display: inline-block;
            box-sizing: border-box;
            animation: rotation 1s linear infinite;
        }
        
        @keyframes rotation {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        } 
       
        .loading-text{
            color: #000;
            font-size: 1.4rem;
            font-weight: 500;
            z-index:10
        }
    
    }
}

//SIDE LAYOUT CSS
.side-layout{
    width: 17%;
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #fff;
    overflow-x: hidden;
    box-shadow: 0px 4px 20px 0px rgba(55, 55, 55, 0.12), 4px 4px 40px 0px rgba(255, 255, 255, 0.25);

    .side-header{
        display: flex;
        max-height: 6.25rem;
        justify-content: baseline;
        align-items: center;
        
        .menu-icon{
            width: 1.5rem;
            height: 1.5rem;  
            margin-top: 2.5rem;
            margin-left: 2.18rem;
        }
        .menu-text{
            color: #828282;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            margin-top: 2.5rem;
            margin-left: 1.125rem;
        }
    }

    .active {
        background: #F7F0FF;
        border-radius: 8px;
        width: 100%;
        height: 3.125rem;
        cursor: pointer;
        padding-left: 1.25rem;
        margin-top: 1.25rem;

        .side-name{
            text-decoration: none;
            color: #656565 !important;
            cursor: pointer;
            padding-top: 1.87rem;

            .side-text{
                display: flex;
                padding: 0.625rem 0 0.625rem 0.94rem;
                align-items: center;
    
                .circle-img{
                    width: 1.5rem;
                    height: 1.5rem;
                    flex-shrink: 0;
                    margin-right: 0.625rem;
                }

                .active-image{
                    margin-right: 1rem;
                }
    
                .text{
                    color: #1B1B1B;
                    font-size: 1.05rem;
                    font-weight: 300;
                    text-transform: capitalize;
                    margin-right: 0.5rem;
              
                }
                .small-text{
                    color: #000;
                    font-size: 0.75rem;
                    font-weight: 300;
                }
            }
        }
    }  
    
    
    .in-active {
        width: 100%;
        height: 3.125rem;
        cursor: pointer;
        margin-left:1rem;
        margin-top: 1rem;

        .side-name{
            text-decoration: none;
            color: #656565 !important;
            cursor: pointer;
            padding-top: 1.87rem;

            .side-text{
                display: flex;
                gap:0.5rem;
                padding:0.625rem 0 0.625rem 0.94rem;
                align-items: center;
                justify-content: baseline;
    
                .circle-img{
                    width: 1.5rem;
                    height: 1.5rem;
                    flex-shrink: 0;
                    margin-right: 0.625rem;
                }
    
                .text{
                    color: #707070;
                    font-size: 1.05rem;
                    font-weight: 300;
                    text-transform: capitalize;
                    margin-right: 1rem;
                }
                .small-text{
                    color: #707070;
                    font-size: 0.75rem;
                    font-weight: 300;
                }
            }
        }
    } 

    .active-sign{
        height: 3.125rem;
        margin-top: 1.25rem;
        position: absolute; 
        object-fit: contain;
        right: 0
    }

    .sidelayout{
        display:flex;
        flex-direction: column;
    }

    .sidelayout-hr{
        display:flex;
        align-items: center;
        justify-content: center;
        margin:auto;
    }

    .lower-sidelayout{
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        top:67%;
        width: 100%;
    }

    .sidelayout-footer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 87%;
        width: 100%;

        .sidelayout-janitri-logo{
            width:8rem;
            height: 3rem;
        }

        .date-footer{
            font-family: Poppins;
            font-size: 0.687rem;
            font-weight: 300;
            line-height: 1rem;
            color: #707070;
         
        }

    }
}


.input-label{
    color:rgba(91, 91, 91, 1);
    font-family: Roboto;
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 1.75rem; 
}

.filter{
    margin-right: 1.25rem;    
}

.reload{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:1.5rem;

    .reload-image{
        margin-bottom: 4rem;
    }

    .reload-text{
        color: #000;
        font-size: 1.875rem;
        font-weight: 350;
    }

    .reload-button{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #FFF;
        padding: 1rem 6rem;
        font-size: 1.125rem;
        font-family: poppins;
        font-weight: 400;
        border-radius: 100px;
        border: 1px solid var(--primary-purple, #A163F1);
        background: #A163F1;
        box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08), 0px 4px 40px 0px rgba(255, 255, 255, 0.12);
    } 
}


.no-recorded-data{
    width:54.37rem;
    height:30rem;
    overflow: hidden;
    position: fixed;
    margin-left: 5%;
    margin-top:5%;
}


/* Screen height between 600px and 700px */
@media (min-height: 37.5rem) and (max-height: 43.75rem) { 
    html {
        font-size: 14px;
    }
    .sidelayout-hr{
        width:90%;
    }
    
}

/* Screen height between 700px and 800px */
@media (min-height: 43.75rem) and (max-height: 50rem) { 
    html {
        font-size: 15px;
    }
    .sidelayout-hr{
        width:90%;
    }
 
}

/* Screen height between 800px and 900px */
@media (min-height: 50rem) and (max-height: 56.25rem) { 
    html {
        font-size: 16px;
    }
    .sidelayout-hr{
        width:90%;
    }
}

/* Screen height between 900px and 1000px */
@media (min-height: 56.25rem) and (max-height: 62.5rem) { 
    html {
        font-size: 17px;
    }
    .sidelayout-hr{
        width:90%;
    }
}

/* Screen height between 1000px and 1100px */
@media (min-height: 62.5rem) and (max-height: 68.75rem) { 
    html {
        font-size: 18px;
    }
    .sidelayout-hr{
        width:90%;
    }
}

/* Screen height above 1100px */
@media (min-height: 68.75rem) { 
    html {
        font-size: 19px;
    }
    .sidelayout-hr{
        width:90%;
    }
}

