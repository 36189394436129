.delay-div{
    border-radius: 8px;
    background: #F9F9F9;
    margin: 10px;
    position: relative;
    height: 110px;

    .warning-div{
        display: flex;
        padding-top: 30px;
       

        .warning{
            width: 43px;
            height: 43px;
            flex-shrink: 0;
            margin: 10px;
        }

        .delayed-message{
            color: #000;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-left: 5px;
        }
    }

    .info{
        right: 6px;
        position: absolute;
        top: 6px;
        
    }
}

.data-div{
    background: #0E0E0E;
    margin: 20px 0px;
    padding: 10px 0px 20px 0px;

    .fhr-fhr2-mhr-div{
        display: flex;
        justify-content: space-around;
        margin-bottom: 20px;
    }

    .uc-div{
        display: flex;
        justify-content: space-evenly;
        margin: 0px 40px;
    }
}


.critical{
    background: #FFF; 
    width: 100px;
}

.normal{
    background: #0E0E0E;
    width: 100px;
}

.critical-number{
    font-weight: 500;
    font-size: 42px;
    line-height: 63px;
    text-align: center;
    color: #E20000;
    height: 50px;
}

.normal-number{
    font-weight: 500;
    font-size: 42px;
    color: #05C901;
    text-align: center;
    height: 50px;

}

.critical-text{
    color: #E20000;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.normal-text-1{
    color: #FEFCFF;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.uc-duration-number{
    color: #FFF;
    text-align: center;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    height: 50px;
}

.uc-text{
    color: #FEFCFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}

.uc-number{
    color: #05C901;
    text-align: center;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    height: 50px;
   
}

.uc{
    width: 120px;
}