.profile-page{
    flex: 1;
    margin-left: 20%; 
    background: #FBF8FF;
    overflow-x: hidden ;
    height: 100vh;
    padding-top: 7rem;
}

.settings{
    display:flex;
    flex-direction: row;
    gap:1rem;
    align-items: center;
    padding-top: 1rem;
}

.hrline-setting{
    width:94%;
    margin-right: 6%;
   
}

.setting-text{
    font-size: 1.2rem;
    font-weight: 400;
}

.hospital-details{
  display: flex;
  padding:1rem;
  flex-direction: column;
  gap:0.8rem;
}

.text-label {
  font-size: 1.1rem;
  font-weight: 300;
  color:#A163F1;
  color:#333;
}

.detail-div{
  display: flex;
  flex-direction: row;
  gap:1rem;
}

.bold{
  font-weight: 500;
}

.theme-toggle-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:1rem;
    margin-right: 4rem;

  }

.toggle-container {
    width: 6.25rem;
    height: 2.3rem;
    background-color: #e0e0e0;
    border-radius:100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.2rem;
    cursor: pointer;
    position: relative;
    transition: background-color 0.1s ease;
}

.toggle-container.light-theme {
    background-color: #d1c4e9; 
    border:1px solid #A163F1; 
    // background-color: #d1c4e9; /* Light theme background */
}

.toggle-container.dark-theme {
    background-color:#A163F1; 
    // background-color: #4527a0; /* Dark theme background */
}

.toggle-circle {
    width:1.8rem;
    height: 1.8rem;
    padding:0.2rem;
    background-color: #fff;
    border:1px solid #A163F1;
    border-radius: 50%;
    text-align: center;
    line-height: 1.8rem;
    font-size:0.7rem;
    font-weight: bold;
    position: absolute;
    transition: transform 0.3s ease, color 0.1s ease;
}

.toggle-container.light-theme .toggle-circle {
    transform: translateX(2px);
    color: #A163F1; 
}

.toggle-container.dark-theme .toggle-circle {
    transform: translateX(3.9rem); 
    color:#333;
}



  
  
  