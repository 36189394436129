.user-card{
   // width:95%;
    //height:5rem;
    padding: 1.3rem;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;


    .alerts-box{
        display:flex;
        flex-direction: column;
        gap:0.5rem;

        .warning-div{
            display: flex;
            flex-direction: row;
            gap: 0.7rem;
            align-items: flex-start;
        }
    }
}

.visited{
    border-radius: 12px;
    border: 1px solid #A50000;
    background-color: #F67979;
    color: #FFFFFF;
    font-weight:300;
    font-size: 18px;
    padding-top: 1rem;
    .bold-text{
        color: #FFF;
        font-size: 22px;
        font-weight:500;
    }
}

.not-visited{
    border-radius: 12px;
    border: 2px solid #FF5858;  
    font-weight:300;
    color: #000;
    font-size: 20px;
   

    .bold-text{
        color: #000;
        font-size: 20px;
        font-weight: 500;
    }
}

.alert-arrow{
    cursor:pointer !important;
}