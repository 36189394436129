@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap');

html{
    margin: 0 !important;
    padding: 0;
    height: 100%;
    
}
body{
    margin: 0 !important;
    background-color: #0e0e0e;
    font-family: 'Poppins', sans-serif;
    height: 100%;
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    width: 0.5rem;
    height: 10px;
    border-radius: 500px;
    opacity: 0px;
  }
  
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ECECEC;
    border-radius: 500px;
  }
   
  ::-webkit-scrollbar-thumb {
    background: #DDCDF1; 
    border-radius: 500px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #DDCDF1;
 
  }

  @media screen and (max-width: 1280px ) {
    html {
      font-size: 12px; /* Reduce the base font size */
    }
  }


/* Screen height between 600px and 700px */
@media (min-height: 37.5rem) and (max-height: 43.75rem) { 
  html {
      font-size: 14px;
  }
 
}

/* Screen height between 700px and 800px */
@media (min-height: 43.75rem) and (max-height: 50rem) { 
  html {
      font-size: 15px;
  }
 
}

/* Screen height between 800px and 900px */
@media (min-height: 50rem) and (max-height: 56.25rem) { 
  html {
      font-size: 16px;
  }
  
}



/* Screen height between 600px and 700px and width between 320px and 768px */
@media (min-height: 37.5rem) and (max-height: 43.75rem) and (min-width: 20rem) and (max-width: 48rem) { /* 600px - 700px height, 320px - 768px width */
  html {
      font-size: 23px;
  }
 
}

/* Screen height between 700px and 800px and width between 768px and 1024px */
@media (min-height: 43.75rem) and (max-height: 50rem) and (min-width: 48rem) and (max-width: 64rem) { /* 700px - 800px height, 768px - 1024px width */
  html {
      font-size: 15px;
  }
 
}

/* Screen height between 800px and 900px and width between 1024px and 1280px */
@media (min-height: 50rem) and (max-height: 56.25rem) and (min-width: 64rem) and (max-width: 80rem) { /* 800px - 900px height, 1024px - 1280px width */
  html {
      font-size: 16px;
  }
 
}

/* Screen height between 900px and 1000px and width between 1280px and 1440px */
@media (min-height: 56.25rem) and (max-height: 62.5rem) and (min-width: 80rem) and (max-width: 90rem) { /* 900px - 1000px height, 1280px - 1440px width */
  html {
      font-size: 17px;
  }
  
}

/* Screen height between 1000px and 1100px and width between 1440px and 1920px */
@media (min-height: 62.5rem) and (max-height: 68.75rem) and (min-width: 90rem) and (max-width: 120rem) { /* 1000px - 1100px height, 1440px - 1920px width */
  html {
      font-size: 18px;
  }
 
}

/* Screen height above 1100px and width above 1920px */
@media (min-height: 68.75rem) and (min-width: 120rem) { 
html {
      font-size: 19px;
  }
 
}







