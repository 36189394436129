.center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    height: 100%;
    background: #FBF8FF;
}

.login-form{
    background: #FFFFFF;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius:20px;
    padding:2rem 3rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}

.form-content{
    width:40rem;
}

.sub-title{
    font-size: 1.3rem;
    font-weight: 600;
    line-height: 1.5rem;
    color:#090909;
    padding-bottom: 1.5rem;
}

.login-janitri-logo{
    width: 15rem;
    height:4.8rem;
    padding-bottom: 2rem;
}


.dialog-login{

    .dialog{
        max-height: 52.1875rem;
        display:flex;
        flex-direction: column;
        gap:1.875rem;
        align-items: center;
        border-radius: 20px;
    }
    
    .step-boxs{
        display:flex;
        flex-direction: row;
        gap:3.5625rem;

        .box{
            width: 10rem; 
            height: 2.5rem; 
            display:flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            background: #E6EFFF; 
            p{
                color: #000;
                font-size: 1.1rem;
                font-weight: 400;
                font-family: 'Poppins', sans-serif;
                line-height: 1.5rem;
                letter-spacing: 0.0938em;
            }
        }
        .arrow-img{
            width: 6rem;
        }
    }

    .gif-box{
        display:flex;
        flex-direction: row;
        gap:1.3rem;
        margin-bottom: 3.25rem;

        .gif{
            display:flex;
            flex-direction: column;
            align-items: center;
            gap:1.25rem;

            p{
                color: #343434;
                font-family: 'Poppins', sans-serif;
                font-size: 1.0625rem;
                line-height: 1.5rem;
                letter-spacing: 0.0538em;
                margin: 0.625rem 0;
            }

            img{
                width: 21.875rem;
                height: 17.1875rem;
            }

            .active-gif{
                width: 21.875rem;
                height: 17.1875rem;
                border-radius: 12px;
                transform: scale(1.15);
            }

            .inactive-gif{
                width: 21.875rem;;
                height: 17.1875rem;
            }
        }
    }

    .MuiDialog-paperWidthSm{
        max-width: 93.75rem;
        border-radius: 1.25rem;
        padding-left: 3.25rem;
        padding-right: 3.25rem;
    }

    .dialog-button{
        border: none;
        cursor:pointer !important;
        margin-top: 0.625rem;
    }
}

.invalid-credential-div{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    border: 1px solid #FFCDCD;
    background: #FFEFEF;
    padding:1rem;
    margin-top: 3rem;

    .error-img{
        width: 1.5rem;
        height:1.5rem;
        margin-right: 1.2rem;
    }
    
    .bold{
        color: #000;
        font-family: Poppins, sans-serif;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
    
    }

    .normal-text{
        font-size: 0.8rem;
        font-weight: 300;
        font-family: 'Poppins', sans-serif;
    }
}

.login-input-field{
    background-color: transparent;
    border: 0px solid;
    font-size: 0.9rem;
    font-family: poppins;
    margin:1rem;
}

.login-input-field:focus {
    outline: none; 
    box-shadow: none; 
    background-color: transparent;
}

.login-input-label{
    color: #090909;
    font-size: 1.125rem;
    font-weight: 600;
    display: flex;
    justify-content: flex-start;
    margin-left:2.2rem;
}
  
.login-input-field::placeholder{
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.8rem;
    text-align: left;
    padding-top: 0.5rem;
    color: rgba(0, 0, 0, 0.50);
}

textarea:focus, input:focus{
    font-size: 1rem;
}

.login-button{
    background: #A163F1;
    box-shadow: 0px 4px 4px 0px #0000001A;
    color:#FFFFFF;
    font-family: Poppins;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.875rem;
    border-radius: 4px;
    padding:0.8rem 0;
    margin: 1.5rem 3.5rem; 
    
}

.passowrd-div{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin:1rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.50);  
}

.passowrd-visible{
    cursor : pointer !important;
}

.partner-and-supporter{
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.5rem;
    padding:1.2rem 0.6rem ;
}

.top-left-logos{
    display: flex;
    gap:1rem;
    width:100%;
    align-items: flex-start;
    padding-top:0.5rem;
    padding-left: 1rem;

}

.left-corner-login-image{
    width:4rem;
    height:4rem;
}

.logo-carousel {
    overflow: hidden;
    white-space: nowrap;
    width: 70%;
    padding-bottom: 2rem;
  }
  
  .logo-track {
    display: flex;
    gap:2rem;
    animation: scroll 60s linear infinite;
  }
  
  .logo-item {
  
    display: inline-block;
  }

  .logo-item img {
    width: 3.75rem;
    height: 3rem;
    filter: blur(0); /* No blur initially */
    transition: filter 0.3s ease-in-out;
  }
  
  /* Animation to scroll the logos */
  @keyframes scroll {
    0% {
      transform: translateX(10%);
    }
    100% {
      transform: translateX(-345%);
    }
  }



/* Screen height between 600px and 700px */
@media (min-height: 37.5rem) and (max-height: 43.75rem) { 
    html {
        font-size: 14px;
    }
}

/* Screen height between 700px and 800px */
@media (min-height: 43.75rem) and (max-height: 50rem) { 
    html {
        font-size: 15px;
    }
}

/* Screen height between 800px and 900px */
@media (min-height: 50rem) and (max-height: 56.25rem) { 
    html {
        font-size: 16px;
    }
}

/* Screen height between 900px and 1000px */
@media (min-height: 56.25rem) and (max-height: 62.5rem) { 
    html {
        font-size: 17px;
    }
    .left-corner-login-image{
        padding-top: 1rem;
    }
  
}

/* Screen height between 1000px and 1100px */
@media (min-height: 62.5rem) and (max-height: 68.75rem) { 
    html {
        font-size: 18px;
    }
    .left-corner-login-image{
        padding-top: 1rem;
    }

   
}

/* Screen height above 1100px */
@media (min-height: 68.75rem) { 
    html {
        font-size: 19px;
    }
    .left-corner-login-image{
        padding-top: 1rem;
    }
   
}








