html{
    background:#0E0E0E;
    width: 100%;
    height: 100%;
}

body{
    background: #0E0E0E;
    width: 100%;
    height: 100%;
}


.main{
    font-family: 'Poppins', sans-serif;
    margin: 0;
    background: #0E0E0E;

    .grid{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 20px;
        margin-bottom: 20px;
    }

    .header{
        display: flex;
        justify-content: space-between;
        border: 1px solid #7A7A7A;
        background: #2C2C2C;
        background-blend-mode: lighten;
        padding: 10px;

        .real-time-monitoring{
            text-align: center;
            color: #FEFCFF;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-top: 8px;
            margin-left: 12rem;
        }

        .search-div{
            display: flex;
           
        }
    }
}
p{
    margin: 0;
}

.critical_card{
    // width: 357px;
    height: 517px;
    background: #2C2C2C;
    border: 2px solid #E20000;
    border-radius: 18.6667px;
    flex-direction: column;
    justify-content: space-around;

    .connected_ellipse{
        display: flex;
     

        .ellipse_green{
            width: 9px;
            height: 9px;
            background: #05C901;
            border-radius: 50%;
            margin-top: 10px;
            margin-left: 3px;
        }
        .connected{
            color: #FEFCFF;
            font-weight: 300;
            font-size: 18px;
            margin-right: 2px;
            
        }
    }


    .imageLogo{
        width: 30px;
        height: 30px;
    }
    .logo{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }
    .name{
        margin-left: 20px;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #FEFCFF
    }
    .patient_id{
        margin-left: 20px;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #FEFCFF
    }

    .normal_inside_card{
        //width: 357px;
        height: 84px;
        background: #0E0E0E;
        display: flex;
        justify-content: space-between;
        padding-left: 20px;
        padding-right: 20px; 
        //margin-top: 10px;

        .logo_name{
            font-weight: 400;
            font-size: 26px;
            line-height: 39px;
            color: #FEFCFF;
            margin: 0;
        }
        .number-div{

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            gap: 0;
            
            .fhr_number{
                font-weight: 500;
                font-size: 42px;
                color: #05C901;
            text-align: center;
            height: 40px;
                
            }
            .duration{
                font-weight: 500;
                font-size: 42px;
                color: #FEFCFF;
                text-align: center;
                height: 40px;
            }
            .bpm{
                
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.02em;
                color: #FEFCFF;
                text-align: center;
            }

            .uc_number{
                font-weight: 500;
                font-size: 42px;
                color: #05C901;
                text-align: center;
                
            }
        }
    }

    .critical_inside_card{
        //width: 357px;
        height: 84px;
        background: #ECECEC;
        display: flex;
        justify-content: space-between;
        padding-left: 20px;
        padding-right: 20px; 
        //margin-top: 10px;

        .number-div{

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            gap: 0;

            .critical_number{
                font-weight: 500;
                font-size: 42px;
                height: 40px;
                text-align: center;
                color: #E20000;
            }
            .critical_bpm{
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.02em;
                color: #E20000;
                text-align: center;
            }
            
            .duration{
                font-weight: 500;
                font-size: 42px;
                color: #FEFCFF;
                text-align: center;
                height: 40px;
            }

            .critical_uc_number{
                font-weight: 500;
                font-size: 42px;
                color: #E20000;
                text-align: center;  
            }
        }

        .critical_logo_name{
            color: #E20000;
            font-weight: 500;
            font-size: 26px;
            line-height: 39px;
        }

        .critical_number{
            font-weight: 500;
            font-size: 42px;
            line-height: 63px;
            text-align: right;
            color: #E20000;
        }
        .critical_bpm{
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.02em;
            color: #E20000;
            text-align: center;
        }
    }
    .disconnected_inside_card{
        //width: 352px;
        height: 84px;
        background: #0E0E0E;
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
    }

    .button_div{
        display: flex;
        justify-content: space-around;
        position: relative;
        margin-top: 30px;
        .delayed-div{
            display: flex;
            flex-direction: column;

            .time{
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                
            }
            .delayed{
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    
        .view_button{
            width: 156px;
            height: 40px;
            background: #A163F1;
            border-color: #A163F1;
            border-radius: 10px;
            font-weight: 500;
            font-size: 22px;
            line-height: 33px;
            color: #FEFCFF;
            cursor: pointer;
            border-style: solid;
        }
    }
   
  
}
.normal_card{
    // width: 357px;
    height: 517px;
    background: #2C2C2C;
    border: 2px solid #A163F1;
    border-radius: 18.6667px;
    flex-direction: column;
    justify-content: space-around;
    //margin-right: 18px;

    .connected_ellipse{
        display: flex;
        

        .ellipse_green{
            width: 9px;
            height: 9px;
            background: #05C901;
            border-radius: 50%;
            margin-top: 10px;
            margin-left: 3px;
        }
        .connected{
            color: #FEFCFF;
            font-weight: 300;
            font-size: 18px;
            margin-right: 2px;
        }
    }


    .imageLogo{
        width: 30px;
        height: 30px;
        
    }
    .logo{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }

    .name{
        margin-left: 20px;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #FEFCFF
    }
    .patient_id{
        margin-left: 20px;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #FEFCFF
    }

    .normal_inside_card{
        //width: 352px;
        height: 84px;
        background: #0E0E0E;
        display: flex;
        justify-content: space-between;
        padding-left: 20px;
        padding-right: 20px;
        //margin-top: 10px;

        .logo_name{
            font-weight: 400;
            font-size: 26px;
            line-height: 39px;
            color: #FEFCFF;
            margin: 0;
        }

        .number-div{

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            gap: 0;
            
            .fhr_number{
                font-weight: 500;
                font-size: 42px;
                color: #05C901;
                text-align: center;
                height: 40px;
                
            }
            .duration{
                font-weight: 500;
                font-size: 42px;
                color: #FEFCFF;
                text-align: center;
                height: 40px;
            }
            .bpm{
                
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.02em;
                color: #FEFCFF;
                text-align: center;
            }

            .uc_number{
                font-weight: 500;
                font-size: 42px;
                color: #05C901;
                text-align: center;
                
            }
        }
        
    }

    .blank{
        width: 352px;
        height: 84px;
    }

    .button_div{
        display: flex;
        justify-content: space-around;
        position: relative;
        margin-top: 30px;

        .delayed-div{
            display: flex;
            flex-direction: column;

            .time{
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
               
            }
            .delayed{
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                
            }
        }
    
        .view_button{
            width: 156px;
            height: 40px;
            background: #A163F1;
            border-color: #A163F1;
            border-radius: 10px;
            border-style: solid;
            font-weight: 500;
            font-size: 22px;
            line-height: 33px;
            color: #FEFCFF;
            cursor: pointer;
        }
    }
}
.disconnected_card{
    // width: 357px;
    height: 517px;
    background: #2C2C2C;
    border: 2px solid #E20000;
    border-radius: 18.6667px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    //margin-right: 18px;

    .imageLogo{
        width: 30px;
        height: 30px;
    }
    .logo{
        display: flex;
        flex-direction: column;
        
    }

    .name{
        margin-left: 20px;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #7A7A7A;
    }
    .patient_id{
        margin-left: 20px;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #7A7A7A;
    }

    .blank{
        width: 352px;
        height: 84px;
    }
    .disconnected_inside_card{
        //width: 357px;
        height: 84px;
        background: #0E0E0E;
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        

        .disconnected_logo_name{
            font-weight: 400;
            font-size: 26px;
            line-height: 39px;
            color: #7A7A7A;
            margin: 0;
            text-align: center;
        }
        .disconnected_number{
            font-weight: 500;
            font-size: 42px;
            color: #7A7A7A;
            text-align: center;
        }
        .disconnected_bpm{
            font-weight: 300;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.02em;
            color: #7A7A7A;
            text-align: center;
        }

       
    }
    .button_div{
        display: flex;
        margin-left: 10px;
        height: 40px;
        margin-top: 25px;
        .connected_ellipse{
            display: flex;
            margin-top: 5px;

            .ellipse_red{
                width: 9px;
                height: 9px;
                background: #E20000;
                border-radius: 50%;
                margin-top: 10px;
                margin-left: 3px;
            }
            .disconnected{
                color: #7A7A7A;
                font-weight: 300;
                font-size: 18px;
            }
        }
    
        .view_button{
            width: 156px;
            height: 40px;
            background: #A163F1;
            border-color: #A163F1;
            border-radius: 10px;
            font-weight: 500;
            font-size: 22px;
            line-height: 33px;
            color: #FEFCFF;
        }
    }
}